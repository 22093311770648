import React, { useEffect, useState } from 'react'
import TopBar from '../../components/top-bar/TopBar'
import { useNavigate } from 'react-router-dom'
import './MainView.css';
import BackEndProvider from '../../lib/backend';
import LoadingProvider from '../../components/loading/LoadingContext';
import MessageProvider from '../../components/message/MessageContext';

type Props = {
    children: React.ReactNode,
    invertTopBar?: boolean,
    topBarVisible?: boolean,
}

const MainView = (props: Props) => {

    const getToTop = (): number => {
        const maxHeight = 500;
        const scrollToTop = (Math.min(window.scrollY, maxHeight) / maxHeight);
        
        return Math.min(scrollToTop * 2, 1);
    }

    const [state, setState] = useState({scrollToTop: getToTop()});
    

    useEffect(() => {

        const onScroll = (): void => {
            const scrollTotop = getToTop();
            setState({scrollToTop: scrollTotop});
        }

        document.addEventListener('scroll', onScroll)

        return() => {
            document.removeEventListener('scroll', onScroll);
        }

    }, [])

    const navigate = useNavigate();

    const {invertTopBar = false, topBarVisible = false} = props;

    return (
        <div id='main-view'>
            <LoadingProvider>
                <MessageProvider>
                    <BackEndProvider>
                        <TopBar invert={invertTopBar} alwaysVisible={topBarVisible} scrollToTop={state.scrollToTop} handleChangeRoute={to => navigate(to)} />
                        {props.children}
                    </BackEndProvider>
                </MessageProvider>
            </LoadingProvider>
        </div>
    )
}

export default MainView