import { Box, Collapse, Typography } from '@mui/material';
import './FormProgressWrapper.css';
import {  ILayout, IQuestion, QuestionKeys, questions } from '../../../constants/questions';
import { CheckCircleOutlineRounded, CircleOutlined } from '@mui/icons-material';
import {  toMoneyFormat } from '../../../utils';
import VTButton from '../../button/VTButton';

interface IFormProgressWrapperProps {
    serverData: Record<string, any>,
    values: Record<string, any>,
    sections: Array<[string, ILayout]>,
    step: number,
    completedSteps: number,
    completed: boolean,
    onValuate: Function,
}

export default function FormProgressWrapper(props: IFormProgressWrapperProps) {

    const {sections, step, completedSteps, completed, values, serverData} = props;

    return (
        <Box className='FormProgressWrapper-root'>
            <Box className='FormProgressWrapper-steps'>

                {
                    sections.map(([section, layout], index) => {

                        const completed  = index < completedSteps;
                        const current = index === step;

                        const sectionQuestions: [QuestionKeys, IQuestion][] = layout.questions.map(question => [question, questions[question]]);

                        return (
                            <Box key={section} className={'FormProgress-box '+(current ? ' VT-current' : completed ? ' VT-completed' :'')}>
                                <Box className='FormProgress-container'>
                                    <Box className='FormProgress-icon'>
                                        {
                                            !completed ? <CircleOutlined/>
                                            : <CheckCircleOutlineRounded/>
                                        }
                                    </Box>
                                    <Typography className='FormProgress-title'>{layout.title}</Typography>
                                </Box>
                                <Box className='FormProgress-container'>
                                    <Box className='FormProgress-line'>
                                        <Box className='FormProgress-line-line'/>
                                    </Box>
                                    <Collapse in={completed || current} unmountOnExit timeout={1000}>
                                        <Box className='FormProgress-answers'>
                                            {
                                                sectionQuestions.map(([key, {answer}], index) => {

                                                    let value = values[key];
                                                    
                                                    if(key === 'sales2' && parseInt(values.age!) < 2) {
                                                        return <Box key={index}/>;
                                                    }
                                                    else if(key === 'sales3' && parseInt(values.age!) < 3) {
                                                        return <Box key={index}/>;
                                                    }
                                                    else if(key === 'sales4' && parseInt(values.age!) < 4) {
                                                        return <Box key={index}/>;
                                                    }
                                                    else if(key === 'sales5' && parseInt(values.age!) < 5) {
                                                        return <Box key={index}/>;
                                                    }

                                                    if(key === 'terms') {
                                                        value = values[key] ? 'Aceptado' : 'No aceptado';
                                                    } else if(key === 'country') {
                                                        value = serverData.countries.find((country: any) => country.id === values[key]).country;
                                                    } else if(key === 'sector'){
                                                        value = serverData.sectors.find((sector: any) => sector.sector === values[key])['sector-spanish'];
                                                    } else if(key === 'industry'){
                                                        value = serverData.industries.find((industry: any) => industry['sub-industry'] === values[key])['sub-spanish'];
                                                    } else if(
                                                        key === 'bruteAverageMargin' || 
                                                        key === 'averageOperativeMargin' || 
                                                        key === 'salesGrowth' || 
                                                        key === 'debtBackupPercentage' || 
                                                        key === 'interestRate' || 
                                                        key === 'isr' 
                                                    ) {
                                                        value = value + '%'
                                                    } else if(
                                                        key === 'sales1' || 
                                                        key === 'sales2' || 
                                                        key === 'sales3' || 
                                                        key === 'sales4' || 
                                                        key === 'sales5' ||
                                                        key === 'debtInFinance'
                                                        || key === 'annualCompanyDepreciation'
                                                        || key === 'capex'
                                                    ) {
                                                        value = '$'+toMoneyFormat(values[key])
                                                    }

                                                    const notAnswered = value === '' || value === '$';

                                                    return(
                                                        <Typography key={index} className={'FormProgress-description ' + (notAnswered ? 'VT-unanswered' : '')}>
                                                            {answer}: {notAnswered ? 'N/A' : value}
                                                        </Typography>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </Collapse>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
            <VTButton color='secondary' sx={{width: '100%'}} disabled={!completed} onClick={props.onValuate}>Valuar</VTButton>
        </Box>
    )
}