import { Box, IconButton, Typography } from '@mui/material';

import './Footer.css';
import { FacebookOutlined, Instagram, WhatsApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import SearchValuation from '../valuation-look/SearchValuation';
import { useState } from 'react';

interface ColumnItem {
  name: string;
  link: string;
  onClick?: () => void;
}


function Footer(){

  const valgoritmo: ColumnItem[] = [
    {name: 'Inicio', link: '/'},
    {name: 'Valgoritmo', link: '/Form'},
    {name: 'Sobre nosotros', link: '/About'},
    {name: 'Valuación', link: '/', onClick: () => setSearchValuation(true)},
  ];

  const tools = [
    {name: 'Valgoritmo', link: '/'},
  ];
  const us = [
    {name: 'Nosotros', link: '/'},
    {name: 'Blog', link: '/'},
    {name: 'VolkTech', link: '/'},
    {name: 'FAQ', link: '/'},
  ];
  
  const columns: {name: string, items: ColumnItem[]}[] = [
    {name: 'Valgoritmo', items: valgoritmo},
    {name: 'Herramientras', items: tools},
    {name: 'Sobre', items: us},
  ]

  const navigate = useNavigate();


  const handleClick = (link: string) => {
    navigate(link);
  }

  const [searchValuation, setSearchValuation] = useState(false);

  return (
    <Box id='VTFooter-root'>
      <SearchValuation in={searchValuation} onClose={() => setSearchValuation(false)}/>
      <Box className='VTFooter-row'>
        {
          columns.map(({name, items}, index) => (
            <Box className='VTFooter-column' key={index}>
              <Typography>{name}</Typography>
              <Box className='VTFooter-column-items'>
                {
                  items.map((item, j) => (
                    <Box key={j} className='VTFooter-column-item' onClick={item.onClick ? item.onClick :() => handleClick(item.link)}>{item.name}</Box>
                  ))
                }
              </Box>
            </Box>
          ))
        }
        <Box className='flex-row'>
          <IconButton>
            <FacebookOutlined/>
          </IconButton>
          <IconButton>
            <Instagram/>
          </IconButton>
          <IconButton>
            <WhatsApp/>
          </IconButton>
        </Box>
      </Box>
      <Box className='bottom-line'>
        <Typography>® 2023 Valgoritmo</Typography>
        <Box className='flex-row'>
          <Typography>Terminos de Uso</Typography>
          <Box width={50}/>
          <Typography>Política de Privacidad</Typography>
        </Box>
        <Typography>Powered by VolkTech</Typography>
      </Box>
    </Box>
  );
}

export default Footer;
