import { Box, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import './PaymentView.css';
import assets from '../../assets';
import { KeyboardArrowLeft } from '@mui/icons-material';
import {Elements} from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import { useBackEnd } from '../../lib/backend';
import { useState } from 'react';
import PlanButton from './PlanButton';
import plans from '../../constants/plans';
import { useLoading } from '../../components/loading/LoadingContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!);

export default function PaymentView() {

  const backend = useBackEnd();
  const {start, stop} = useLoading();

  const [selectedPlan, setSelectedPlan] = useState(2);
  const [plan, setPlan] = useState(plans.at(1)!);

  const handleReturnBtn = () => {
    window.history.go(-1);
  }

  const [clientSecret, setClientSecret] = React.useState<string>();

  const handleChangePlan = (index: number) => {
    start();
    setSelectedPlan(index);
  }

  React.useEffect(() => {

    start();

    backend.getData('token', '/payment?plan='+selectedPlan).then(
      async response => {
        if(response.status === 200) {
          const data = await response.json();
          setClientSecret(data.client_secret);
          setPlan(data.plan);
          setTimeout(() => stop(), 1000);
        }
      }
    )

  }, [backend, selectedPlan, start, stop]);

  if(clientSecret === undefined){
    return <Box/>
  }

  const options: StripeElementsOptions = {
    clientSecret: clientSecret,
    locale: 'es',
  }

  return (
    <Elements key={clientSecret} stripe={stripePromise} options={options} >
      <Box id='payment-view' className='main-view'>
        <Box id='PaymentForm-container'>
          <Box className='PaymentForm-top flex-row'>
            <IconButton onClick={handleReturnBtn}>
              <KeyboardArrowLeft/>
            </IconButton>
          </Box>
          <PaymentForm plan={plan} clientSecret={clientSecret} completePayment={handlePayment}/>
          <Typography className='disclaimer'>Al hacer el pago, autorizas a Valgoritmo a hacer el cobro por el servicio de "Evaluacion de Marca" de acuerdo a los terminos y condiciones de la empresa.</Typography>
        </Box>
        <Box id='PaymentForm-background-container'>
          <Box className='PaymentForm-background'>
            <Typography className='PaymentForm-background-header'  >Valuación de Empresas y Marcas</Typography>
            <Typography className='logo'>Valgoritmo</Typography>
            <Typography className='sub-header'><span>{plan.price}</span> - {plan.priceSecondary}</Typography>
            <ul>
              <li><Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet feugiat est. In facilisis fermentum quam, non mollis justo gravida ut. In hac habitasse platea dictumst.</Typography></li>
              <li><Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet feugiat est. In facilisis fermentum quam, non mollis justo gravida ut. In hac habitasse platea dictumst.</Typography></li>
              <li><Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet feugiat est. In facilisis fermentum quam, non mollis justo gravida ut. In hac habitasse platea dictumst.</Typography></li>
            </ul>

            <Box className='flex-row space-between' sx={{width: '100%', padding: '50px', boxSizing: 'border-box'}}>
              <PlanButton onClick={() => handleChangePlan(1)} plan={plans.at(1)!} selected={selectedPlan === 1}/>
              <PlanButton onClick={() => handleChangePlan(2)} plan={plans.at(2)!} selected={selectedPlan === 2}/>
            </Box>
            <span className='background-circle first-circle' />
            <span className='background-triangle first-triangle'><img src={assets.svg.triangle} alt='' /></span>
            <span className='background-triangle second-triangle'><img src={assets.svg.triangle} alt='' /></span>
            <span className='background-triangle third-triangle'><img src={assets.svg.triangle} alt='' /></span>
          </Box>
        </Box>
      </Box>
    </Elements>
  );

  function handlePayment(name: string, email: string){
    backend.postData('token', '/payment', {email, name, plan});
  } 


}
