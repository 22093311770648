import React from 'react';
import { VTInputProps } from '../../components/inputs/inputs';
import { createRipples } from '../../utils';

interface IPayButtonProps extends VTInputProps{
}

interface IPayButtonState {
}

export default class PayButton extends React.Component<IPayButtonProps, IPayButtonState> {
    constructor(props: IPayButtonProps) {
        super(props);

        this.state = {
        }
    }

    handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        createRipples({event: e});
        if(this.props.onClick) this.props.onClick();
    }

    public render() {

        return (
        <button className='PayButton-root' type='submit' style={{border: 'none'}} onClick={this.handleClick}>
            {this.props.children}
        </button>
        );
    }
}