'use client'
import { Box, Button, Typography } from '@mui/material';
import './Message.css';

interface IMessageProps {
    open: boolean,
    onClose?: () => void,
    title: string,
    description?: string,
    children?: React.ReactNode,
}


export default function Message(props: IMessageProps) {
    
    const {open, onClose} = props;

    const handleClose = () => {
        if(onClose) onClose();
    }

    return (
        <Box className={'Message-root ' + (open ? 'VT-opened' : '')}>
            <Box className='Message-curtain' onClick={handleClose}/>
            <Box className='Message-box'>
                <Typography className='Message-title'>{props.title}</Typography>
                <Typography className='Message-description'>{props.description}</Typography>
                {
                    props.children ? props.children 
                    : <Button variant='contained' onClick={handleClose}>Ok</Button>
                }
            </Box>
        </Box>
    )
}