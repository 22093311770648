'use client'
import { Box, Button, Fade, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import './SearchValuation.css';
import { useNavigate } from 'react-router-dom';
import { useBackEnd } from '../../lib/backend';

interface ISearchValuationProps {
    in: boolean,
    onClose: () => void,
}

interface ISearchValuationState {
    id: string,
    error: string | null,
}

export default function SearchValuation(props: ISearchValuationProps) {

    const navigate = useNavigate();
    const backend = useBackEnd();

    const [state, changeState] = useState<ISearchValuationState>({
        id: '',
        error: null,
    });

    const setState = (nv: Partial<ISearchValuationState>) => changeState(prev => ({...prev, ...nv}));

    const handleOnClick = () => {

        backend.getData('token', '/form?id='+state.id).then(
            response => {
                if(response.status === 200) {
                    navigate('/Form/Results?id=' + state.id);
                    handleClose();
                } else {
                    setState({error: 'folio invalido'});
                }
            }
        )
    }

    const handleClose = () => {
        props.onClose();
        setState({id: '', error: null});
    }
    
    return (
        <Fade in={props.in} timeout={500}>
            <Box className='SearchValuation-root'>
                <Box className='BlackCurtain' />
                <Box className='SearchValuation-card'>
                    <Typography marginBottom={2}>Ingresa el folio para cargar tu Valuación realizada</Typography>
                    <Box className='flex-column' marginBottom={2}>
                        <Typography>Folio</Typography>
                        <TextField
                            error={Boolean(state.error)}
                            helperText={state.error}
                            value={state.id}
                            onChange={e => setState({id: e.target.value})}
                            variant='standard'
                        />
                    </Box>
                    <Box className='flex-row space-between' width={'100%'}>
                        <Button onClick={handleClose} sx={{color: 'gray'}}>cancelar</Button>
                        <Button variant='contained' onClick={handleOnClick}>Cargar</Button>
                    </Box>
                </Box>
            </Box>
        </Fade>
    )
}