import React, { ReactElement,} from 'react';
import { FormControl, InputLabel, MenuItem, Select as MuiSelect,} from '@mui/material';

interface ISelectProps {
    value: any,
    onChange: (nv: any) => void,
    children: Array<ReactElement>,
    label?: string,
    required?: boolean,
    disabled?: boolean,
    fullwidth?: boolean,
    canBeNull?: boolean,
}

export default class Select extends React.Component<ISelectProps> {
    constructor(props: ISelectProps) {
        super(props);

        this.state = {
        }
    }

    public render() {

        const {value = '', onChange, required = false, canBeNull = false, disabled = false, ...other} = this.props;
        return (
            <FormControl disabled={this.props.children.length === 0 || disabled}>
                <InputLabel>{other.label}</InputLabel>
                <MuiSelect
                  value={value} 
                  required={required}
                  onChange={e => onChange(e.target.value)}
                  
                  {...other}
                >
                  {canBeNull && <MenuItem key='n/a' value='' selected={value === ''} className='VTMenuItem-null'><em>N/A</em></MenuItem>}
                  {this.props.children}
                </MuiSelect>
            </FormControl>
        );
    }
}



