import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IAnswers, formLayout } from '../../constants/questions';
import Form from '../../components/form/Form';
import './MixForm.css';
import { useBackEnd } from '../../lib/backend';
import { useState } from 'react';

export default function MixForm() {
    const navigate = useNavigate();
    const backend = useBackEnd();

    const [errors, setErrors] = useState<Record<string, string>>({});
    const [step, setStep] = useState<number>(0);

    const handleValuateBrand = async (answers: IAnswers) => {
        // const results = valuateCompany(answers);
        
        
        
        const response = await backend.sendData('token', answers);
        if(response.status !== 200) {
            const json = await response.json();
            const {errors} = json;

            setErrors(errors);
            setStep(errors.step);
        } else if(response.status === 200) {
            const json = await response.json();
            setErrors({});
            navigate('Results?id='+json.id);
        }
    }
    
    return (
        <Box className='MixForm-root'>
            <Form layouts={formLayout} onValuate={handleValuateBrand} errors={errors} step={step} setStep={setStep}/>
        </Box>
    )
}