import { Box, Typography } from '@mui/material'
import CallToAction from '../../components/call-to-action/CallToAction';
import { useNavigate } from 'react-router-dom';

type Props = {
    header: string;
    subHeader: string;
    emphasis?: Array<string>;
}

const LandingBanner = (props: Props) => {

    const {header, subHeader, emphasis} = props;

    const navigate = useNavigate();

    const separateWords = () => {

        const phrase = [];
        let lastIndex = 0;

        emphasis?.forEach((word, i) => {
            const index = header.indexOf(word);
            phrase.push([header.substring(lastIndex, index), '']);
            phrase.push([word, i % 2 ? 'blue' : 'orange']);
            lastIndex = index + word.length;
        });

        if(header.substring(lastIndex).length > 0) phrase.push([header.substring(lastIndex), '']);
        return phrase;
    }

    const headerSeparated = separateWords();

    const navigateTo = (to: string) => navigate(to);

    return (
        <Box className='VTBanner-root'>
            <Typography className='VTBanner-heroText'>
                {
                    headerSeparated.map(([words, color], index) => (
                        <span key={index} className={emphasis?.includes(words) ? 'emphasis VT-'+color : ''}>{words}</span>
                    ))
                }
            </Typography>
            <Typography className='VTBanner-subHeader'>{subHeader}</Typography>
            <Box className='flex-row'>
            <CallToAction onClick={() => navigateTo('/Form')}>Iniciar proceso de valuación</CallToAction>
            </Box>
        </Box>
    )
}

export default LandingBanner