import * as React from 'react';
import { Box } from '@mui/material';

import './VTButton.css';
import { createRipples } from '../../utils';
import { VTInputProps } from '../inputs/inputs';

export interface IVTButtonProps extends VTInputProps{
}

export interface IVTButtonState {
}

export default class VTButton extends React.Component<IVTButtonProps, IVTButtonState> {
  constructor(props: IVTButtonProps) {
    super(props);

    this.state = {
    }
  }

  handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if(this.props.disabled) return;
    createRipples({event: e});
    if(this.props.onClick){
        this.props.onClick();
    }
  }

  public render() {

    const {color='primary', sx={}, variant = 'default', fullwidth, disabled} = this.props;

    return (
      <Box className={'VTButton-root ' + (color) + ' VT-'+(variant) + (fullwidth ? ' VT-fullwidth' : '') + (disabled ? ' VT-disabled' : '')} sx={{...sx}} onClick={this.handleClick}>
        <Box className='VTButton-cover' />
        <Box className='VTButton-child'>
            {this.props.children}
        </Box>
      </Box>
    );
  }
}
