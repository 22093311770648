import homeBackground from './home-background.jpeg';
import formBackground from './form-background.jpeg';
import celebration from './celebration.jpg';
import thinker from './thinker.jpeg';
import handshake from './handshake.jpeg';
const images = {
    homeBackground,
    formBackground,
    celebration,
    thinker,
    handshake,
};

export default images;