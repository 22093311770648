'use client'
import { Box, Button, Typography } from '@mui/material';
import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

interface BannerData {
    name: string,
    text: string,
    id: string,
    onChange: () => void,
    value: number,
}

interface IFormResultsProps {
    id: 'brand' | 'company',
    selected: boolean,
    blocked: boolean,
    bannerData: BannerData,
    children: ReactNode,
}

const FormResults = forwardRef((props: IFormResultsProps, ref: ForwardedRef<HTMLDivElement | undefined>) => {

    const navigate = useNavigate();

    const {blocked, bannerData, selected} = props;
    
    return (
        <Box className={'CompanyResults-body ' + (selected ? 'VT-selected' : '')} ref={ref}>
            {blocked && selected && <Box className='CompanyResults-blocked'>
                <Typography>Para desbloquear el contenido</Typography>
                <Button onClick={() => navigate('/Payment?id='+bannerData.id)}>Haga click aquí</Button>
            </Box>}
            <Box className='CompanyResults-banner'>
                <Box className='flex-row full-width items-end space-between'>
                    <Box>
                        <Typography className='CompanyResults-banner-name'>{bannerData.name}</Typography>
                        <Typography className='CompanyResults-banner-text'>{bannerData.text} {bannerData.value}</Typography>
                        <Typography className='CompanyResults-banner-id'>Folio: {bannerData.id}</Typography>
                    </Box>
                    <Box className='flex-column items-end' sx={{width: 'fit-content'}}>
                        <Button className='CompanyResults-banner-change' onClick={bannerData.onChange}>{props.id === 'brand' ? 'ver valor de empresa' : 'ver valor de marca'}</Button>
                    </Box>
                </Box>
            </Box>
            {
                props.children
            }
        </Box>
    )
});

export default FormResults;