'use client'
import { Box, Table, TableCell, TableRow, Typography } from '@mui/material';
import './FAQ.css';


export default function FAQ() {

    const tableData = [
        ['¿Qué es Valgoritmo?', 'Valgoritmo es un servicio que le da certeza en la valuación a una comunidad global de negocios.Los valores que recibes pueden servirte como un “norte” para tomar decisiones estratégicas, a un costo muy accesible. Nuestra información es constantemente actualizada y mejorada.'],
        ['¿Cuánto cuesta Valgoritmo?', 'Valgoritmo funciona mejor con una Tablet o una laptop.  Nuestras tarifas son flexibles. Planes desde $499 a la semana, $1,497 al mes o hasta $10,479 por un año. Sin costos adicionales ni contratos.'],
        ['¿Dónde puedo usar Valgoritmo?', "Usa Valgoritmo donde quieras y cuando quieras. Usa tu cuenta de Valgoritmo para realizar las simulaciones y obtén valuaciones al instante a través de Valgoritmo.mx desde tu computadora personal o algún dispositivo con conexión a internet."],
        ['¿Cómo cancelo?', 'Valgoritmo es sencillo. No te preocupes por acuerdos complicados ni estar atado a compromisos.  Puedes cancelar tu membresía en línea con solo dos clics, y no hay costos adicionales por cancelar. Puedes comenzar y dejar de usarlo cuando quieras.'],
        ['¿Qué puedo hacer con Valgoritmo?', 'Nuestro producto te da un indicador o “norte” del valor de tu marca y tu negocio. Esto te permitirá tomar decisiones estratégicas para mejorar el valor del negocio al que tanto trabajo has invertido, pero también tener una idea muy clara para llegar acuerdo con socios, posibles compradores, o inclusive para comprar otros negocios.'],
    ]
    
    return (
        <Box className='FAQ-root'>
            <Typography className='FAQ-title'>Preguntas Frecuentes</Typography>
            <Table className='FAQ-table'>
                {
                    tableData.map((row, index) => (
                        <TableRow key={index}>
                            {
                                row.map((cell, jindex) => (
                                    <TableCell key={jindex}>{cell}</TableCell>
                                ))
                            }
                        </TableRow>
                    ))
                }
            </Table>
        </Box>
    )
}