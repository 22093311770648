
export const companyYears: Array<{value: number, name: string}> = [
    {value: 0, name: 'Start Up' },
    {value: 3, name: '3 - 5 años'},
    {value: 6, name: '6 - 10 años'},
    {value: 11, name: '11 - 19 años'},
    {value: 20, name: '20+ años'},
]

export const companyCurrency = [
    {value: 'mexicanPeso', name: 'Peso Mexicano'},
    {value: 'usDollar', name: 'Dolar Americano'},
    {value: 'euro', name: 'Euro'},
]

export const customerFrecuency = [
    {value: 'once', name: 'Una vez en la vida'},
    {value: 'annual', name: '1 vez al año'},
    {value: 'biannual', name: '1 vez cada 6 meses'},
    {value: 'trimestral', name: '1 vez cada 3 meses'},
    {value: 'monthly', name: '1 vez cada mes'},
    {value: 'bimonthly', name: '1 vez cada 15 dias'},
    {value: 'weekly', name: '1 vez cada semana'},
    {value: 'everyOtherDay', name: 'más de 2 veces cada semana'},
]

export const currencies: Array<string> = [
    'Peso Mexicano', 'Dolar Americano', 'Euro',
]