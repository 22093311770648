import { CheckBoxOutlineBlankRounded, CheckBoxRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { VTInputProps } from '../inputs';

export interface IVTSingleCheckBoxProps extends VTInputProps{
  value?: boolean;
  onValueChange: Function;
}

export interface IVTSingleCheckBoxState {
}

export default class VTSingleCheckBox extends React.Component<IVTSingleCheckBoxProps, IVTSingleCheckBoxState> {
  constructor(props: IVTSingleCheckBoxProps) {
    super(props);

    this.state = {
    }
  }

  handleClick = () => {
    this.props.onValueChange();
  };

  public render() {

    const {label, children, value = false, errorText} = this.props;

    const error = errorText !== undefined;

    return (
        <Box className={'VTInput-root' + (this.props.required ? ' VT-required' : '') + (error ? ' VT-error' : '')}>
            <Typography className='VTInput-label'>{label}</Typography>
            <Box className={'VTSingleCheckBox-container ' + (value ? 'checked' : '')}>
              <Box className='VTSingleCheckBox-checkbox' onClick={this.handleClick}>
                <CheckBoxRounded className='VTSingleCheckBox-icon-checked'/>
                <CheckBoxOutlineBlankRounded className='VTSingleCheckBox-icon-not-checked'/>
              </Box>
              <Box className='VTSingleCheckBox-child'>
                {children}
              </Box>
            </Box>
            <Box className='VTInput-errortext'>{errorText}</Box>
        </Box> 
    );
  }
}
