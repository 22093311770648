import {createBrowserRouter} from 'react-router-dom';
import Landing from './views/landing/Landing';
import MainView from './views/main-view/MainView';
import PaymentView from './views/pay-view/PaymentView';
import CompanyResults from './views/company-results/CompanyResults';
import MixForm from './views/mix-form/MixForm';
import OrderComplete from './views/order-complete/OrderComplete';
import AboutView from './views/about/AboutView';

const router = createBrowserRouter([
    {
        path: '/',
        element: <MainView><Landing/></MainView>,
    },
    {
        path: '/AboutUs',
        element: <MainView><Landing/></MainView>,
    },
    {
        path: '/Payment',
        children: [
            {
                path: '',
                element: <MainView invertTopBar><PaymentView/></MainView>,
            },
            {
                path: 'Complete',
                element: <MainView topBarVisible><OrderComplete/></MainView>,
            },
        ],
    },

    {
        path: '/Form',
        children: [
            {
                path: '',
                element: <MainView><MixForm/></MainView>,
            },
            {
                path: 'Results',
                element: <MainView><CompanyResults/></MainView>,
            },
        ]
    },
    {
        path: '/About',
        children: [
            {
                path: '',
                element: <MainView invertTopBar topBarVisible><AboutView/></MainView>,
            },
        ]
    },
]);

export default router;