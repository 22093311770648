'use client'
import { Box } from '@mui/material';
import './Loading.css';

export default function Loading() {
    return (
        <Box className={'Loading-root'}>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </Box>
    )
}