import { currencies } from "./constants/company-data";
import { IAnswers, QuestionKeys, brandDefinitions, questions } from "./constants/questions";

export const createRipples = (values: {event: React.MouseEvent<HTMLElement, MouseEvent>, color?: string, target?: EventTarget & HTMLDivElement | null, fixed?: boolean}) => {

    const {event, color, target, fixed} = values

    const button = target === undefined ? event.currentTarget : target;
    const circle = document.createElement("span");

    if(button !== null) {
        const size = button.offsetWidth;
        const pos = button.getBoundingClientRect();
        const x = event.clientX - pos.x - (size / 2);
        const y = event.clientY - pos.y - (size / 2);

        circle.style.height = size+'px';
        circle.style.width = size + 'px';
        circle.style.left = x + 'px';
        circle.style.top = y + 'px';
        circle.classList.add("ripple"); 
        if(color) {
            circle.style.backgroundColor = color;
        }
        if(fixed){
            circle.style.position = 'fixed';
        }
        
        const ripple = button.getElementsByClassName("ripple")[0];

        if (ripple) {
            ripple.remove();
        }
    
        button.appendChild(circle);
    }
};

export function toMoneyFormat(value: string | number, decimals?: number): string {

    if(value === '') return '';
    if(Number.isNaN(value)) return 'NaN';

    if(decimals === undefined && typeof value === 'string'){
        const d = value.split('.')[1];
        if(d !== undefined) decimals = d.length;
    }
    
    const number = typeof value === 'number' ? value : parseFloat(value);

    if(isNaN(number)) {
        console.log(number, value);
        throw new Error(value + ' is not a valid value');
    }

    let [integer, decimal] = number.toFixed(decimals !== undefined ? decimals : 0).split('.');

    const integerArray = integer.split('').reverse();
    const finalArray: Array<string> = [];
    integerArray.forEach((char, index) => {
        if(index % 3 === 0 && index !== 0) finalArray.push(',');
        finalArray.push(char);
    });

    finalArray.reverse();

    if(value.toString().at(value.toString().length -1) === '.') finalArray.push('.');

    
    return finalArray.join('') + (decimal !== undefined ? '.' +decimal : '');
}

export function fromMoneyToJoinedFormat(value: string): string {

    if(value === '') return '';
    else if(value === '.') return '0.';

    value = value.split(',').join('');
    
    const number = parseFloat(value);

    if(isNaN(number)) throw new Error(value + ' is no a valid value');
    
    return value;
}

export function isValidMoneyChar(char: string): boolean {
    if(char.length !== 1) return false;

    return !isNaN(+char) || char === ',' || char === '.' || char === '$';
}

export function numberToPercentage(number: number): string {
    return `${number}%`;
}

export function clamp (value:number, max: number, min: number): number {

    if(value > max) return max;
    else if(value < min) return min;
    else return value;
}


export const progressValues = (values: IAnswers): Partial<Record<QuestionKeys, string>> => {
    
    const answers: Partial<Record<QuestionKeys, string>> = {};

    Object.entries(values).forEach(([key, value]) => {
        const k = key as QuestionKeys;
        if(value === '') answers[k] = value;
        else {
            if(['brandDefinition', 'currency'].includes(k)) {
                const v = value as number;
                if(k === 'brandDefinition') answers[k] = brandDefinitions[v];
                else if(k === 'currency') answers[k] = currencies[v];

            }
            else {
                const {answerType} = questions[k];

                switch(answerType){
                    case "boolean": 
                        answers[k] = value ? 'Si' : 'No';
                    break;

                    case "percentage":
                        answers[k] = value.toString() + ' %';
                    break;
                    
                    case "money":
                        answers[k] = '$ '+toMoneyFormat(value.toString());
                    break;
                    
                    default: answers[k] = value.toString();
                }
            }
        }
    });

    return answers;
}


export function planPriceToNumber(price: string): number {

    return parseFloat(price.replace('MXN $', '').replace(',', ''));

}