import { ReactNode, createContext, useContext, useState } from 'react';
import Message from './Message';

interface IMessageContextProps {
    open: (
        title: string,
        description?: string,
    ) => void,
}

const MessageContext = createContext<IMessageContextProps>({
    open: function (): void {
        throw new Error('Function not implemented.');
    }
});

const MessageProvider = ({children}: {children: ReactNode}) => {

    const [open, setOpen] = useState(false);
    const [messageProps, setMessageProps] = useState<{title: string, description?: string}>({
        title: 'Oops',
        description: '',
    });

    return (
        <MessageContext.Provider value={{
            open(title: string, description?: string) {
                setMessageProps({title, description});
                setOpen(true);
            },
        }}>
            <Message open={open} {...messageProps} onClose={() => setOpen(false)}/>
            {children}
        </MessageContext.Provider>
    )
}

export const useMessage = () => {
    return useContext(MessageContext)
}

export default MessageProvider;