import * as React from 'react';
import { VTInputProps } from '../inputs';
import { Box, Typography } from '@mui/material';
import { Circle } from '@mui/icons-material';

export interface IVTSliderProps extends Omit<VTInputProps, 'onChange'>{
  min?: number,
  max?: number,
  steps?: number,
  value: number,
  onChange?: Function,
  valueString?: (newValue: number) => string,
}

export interface IVTSliderState {
  left: number;
  diff: number;
  rotation: number;
}

export default class VTSlider extends React.Component<IVTSliderProps, IVTSliderState> {

  timeout = 5;
  counter = 0;

  constructor(props: IVTSliderProps) {
    super(props);
    if(props.max !== undefined && props.min !== undefined){
      if(props.max < props.min || props.max === props.min) throw Error;
    } else {
      if(props.max !== undefined && props.min === undefined) throw Error;
      else if(props.max === undefined && props.min !== undefined) throw Error;
    }

    const {max = 1, min = 0} = props;

    const diff = max - min;
    const left = (props.value - min) / diff * 100;
    this.state = {left: left, diff: diff, rotation: 0};
  }

  onDragStart = (container: HTMLElement, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const pageX = e.pageX;
    container.onmouseup = () => this.onDragEnd(container);
    document.onmouseup = () => this.onDragEnd(container);
    container.onmousemove = (e: MouseEvent) => this.drag(container, e.clientX);
    document.onmousemove = (e: MouseEvent) => this.drag(container, e.clientX);
    this.drag(container, pageX);
    this.setState({rotation: 0})
  }
  drag = (elmt: HTMLElement, pageX: number) => {

    const container = elmt;
    const position = container.getBoundingClientRect();
    const width = container.offsetWidth;
    
    const x = (pageX - position.x) / width;
    const newValue = Math.round((Math.min(Math.max(x, 0), 1) * this.state.diff + (this.props.min ? this.props.min : 0)))

    if(newValue !== this.props.value) {
      this.counter = 0;
      const rotation = this.props.value - newValue;
      this.setState({rotation: rotation < 0 ? Math.max(rotation, -2) : Math.min(rotation, 2)});
    } else {
      this.counter += 1;
      if(this.counter >= this.timeout){
        this.setState({rotation: 0});
      }
    }

    if(this.props.onChange){
        this.props.onChange(newValue);
    }
  }
  
  onDragEnd = (container: HTMLElement) => {
    container.onmouseup = null;
    container.onmousemove = null;
    document.onmousemove = null;
    document.onmouseup = null;
    this.setState({rotation: 0});
  }

  componentDidUpdate(prevProps: Readonly<IVTSliderProps>, prevState: Readonly<IVTSliderState>, snapshot?: any): void {
    
    if(prevProps.value !== this.props.value) {
      
      const {value, min = 0} = this.props;
      this.setState({left: (value - min) / this.state.diff * 100})
    }
  }
  

  public render() {

    const {label, valueString = (newValue) => newValue.toString(), sx} = this.props;

    const {min = 0, max = 100, value = 40} = this.props;

    const steps = this.props.steps ? this.props.steps : max - min;

    return (
        <Box className={'VTInput-root' + (this.props.required ? ' VT-required' : '')} sx={sx}>
            <Typography className='VTInput-label'>{label}</Typography>
            <Box className='VTSlider-container'>
              <Typography>{min}</Typography>
              <Box className='VTSlider-touch-container' onMouseDown={e => this.onDragStart(e.currentTarget, e)}>
                <Box className='VTSlider-slider-container'sx={{left: this.state.left + '%'}} >
                  <Box className='VTSlider-tooltip-container' sx={{transform: `translate(-50%, -50%) rotate(${20 * this.state.rotation}deg) `}}>
                    <Box className='VTSlider-tooltip'>
                      <Box className='VTSlider-tooltip-inner'>
                        <Typography>{valueString(value)}</Typography>
                      </Box>
                      <Box className='VTSlider-drop'></Box>
                    </Box>
                  </Box>
                  <Box className='VTSlider-touch-slider'/>
                </Box>
                {
                  Array.from({length: steps + 1}, (_, index) =>(
                    <Circle key={index} style={{left: (index / (steps) * 100 + '%')}}/>
                  ))
                }
              </Box>
              <Typography>{max}</Typography>
            </Box>
        </Box>
    );
  }
}
