import { Box } from '@mui/material'

import './PlanSection.css';
import PlanTable from './PlanTable';
import plans from '../../../constants/plans';



const PlanSection = () => {
  return (
    <Box className='PlanSection-root'>
      {/* <Box className='PlanSection-plans flex-row'>
          {
            plans.map((plan, index) => (
              <PlanCard key={index} {...plan}/>
            ))
          }
      </Box> */}
      <Box>
        <PlanTable plans={plans}/>
      </Box>
    </Box>
  )
}

export default PlanSection