import { Box, Typography } from '@mui/material';
import * as React from 'react';
import './TopBar.css';
import TopBarButton from './TopBarButton';

export interface ITopBarProps {
  scrollToTop?: number;
  invert?: boolean;
  alwaysVisible?: boolean,
  handleChangeRoute: (route: string) => void,
}

export interface ITopBarState {
    selected: number | null,
    scrollToTop?: number,
}

export default class TopBar extends React.Component<ITopBarProps, ITopBarState> {

  buttons = [
    {label: '¿Cómo funciona?', route: '/About'},
    {label: 'Valuar',     route: '/Form'},
  ];
  
  constructor(props: ITopBarProps) {
    super(props);
    
    this.state = {
      selected: null,
      scrollToTop: props.alwaysVisible ? 1 : props.scrollToTop !== undefined ? props.scrollToTop : 0.5,
    }
  }

  componentDidUpdate(prevProps: Readonly<ITopBarProps>, prevState: Readonly<ITopBarState>, snapshot?: any): void {
    if(prevProps.scrollToTop !== this.props.scrollToTop || this.props.alwaysVisible !== prevProps.alwaysVisible) {
      if(!this.props.alwaysVisible) {
        this.setState({scrollToTop: this.props.scrollToTop});
      }
    }
  }
  
  handleClickOnBtn = this.props.handleChangeRoute;
  
  public render() {
    
    const {invert = false} = this.props;

    const {scrollToTop = 0} = this.state;

    const backgroundColor = invert ? '255, 255, 255, ' : '40, 40, 40, ';
    
    return (
      <Box id='top-bar' sx={{backgroundColor: 'rgba(' + backgroundColor + scrollToTop+')', boxShadow: `0 2px 20px 0px rgba(0,0, 0, ${(scrollToTop * 0.25)})`}}>
        <Box className={'top-bar-container ' + (invert ? ' VT-inverted' : '')}>
          <Typography className='logo' onClick={() => this.handleClickOnBtn('/')}>Valgoritmo</Typography>
          <Box>
              {
                  this.buttons.map(({label, route}, index) => (
                      <TopBarButton 
                        key={index} 
                        selected={this.state.selected === index} 
                        route={route}
                        onClick={this.handleClickOnBtn}
                      >
                        {label}
                      </TopBarButton>
                  ))
              }
          </Box>
        </Box>
      </Box>
    );
  }
}
