'use client'
import { Box, Divider, TextField, Typography } from '@mui/material';
import { FormEvent, useState } from 'react';
import {  toMoneyFormat } from '../../utils';
import PayButton from './PayButton';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useLoading } from '../../components/loading/LoadingContext';
import { useMessage } from '../../components/message/MessageContext';
import { useBackEnd } from '../../lib/backend';

interface IPaymentFormProps {
    plan: Record<string, any>,
    clientSecret: string,
    completePayment: (name: string, email: string) => void,
}
interface IPaymentFormState {
  name: string,
  email: string,
}

export default function PaymentForm(props: IPaymentFormProps) {
  
  const {plan} = props;

  const loading = useLoading();
  const messages = useMessage();
  const backend = useBackEnd();

  const stripe = useStripe();
  const elements = useElements();

  const subTotal = plan.charge as number;
  const taxes = subTotal * .16;
  const total = subTotal + taxes;

  const [state, changeState] = useState<IPaymentFormState>({
    name: '',
    email: '',
  });

  const setState = (nv: Partial<IPaymentFormState>) => changeState(prev => ({...prev, ...nv}));

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    loading.start();
    e.preventDefault();

    if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
    }

    const response = await backend.postData('token', '/payment/verify-client', {email: state.email});
    loading.stop();

    if(response.status === 400) {
      messages.open('Oops', 'Hubo un error desconocido');
      return;
    } else if(response.status === 409) {
      messages.open('Espera', 'El correo ' + state.email + ' ya esta suscrito');
      return;
    } 

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        receipt_email: 'sebastian.woolfolk@volktech.com.mx',
        return_url: handleReturlURL(),
      },
    });


    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)

      const error = result.error;
      
      const type = error.type;

      let message: string | undefined = '';

      switch(type){
        
        case 'api_connection_error':
        case 'api_error':
        case 'idempotency_error':
        case 'invalid_request_error':
          message = 'Error del servidor';
        break;
        case 'rate_limit_error':
          message = 'Error en el servidor';
        break;
        
        case 'authentication_error':
          message = 'Autenticación';
        break;
        
        case 'card_error':
          message = 'Tarjeta';
        break;
        
        case 'validation_error':
          message = 'Validación';
        break;
      }

      messages.open(message, error.message?.toString());
      
    } else {

    }

  };

  
  return (
      <form className='PaymentForm-middle' onSubmit={handleSubmit}>
          <Box className='PaymentForm-form'>
            <Box className='flex-column'>
              <Typography className='PaymentForm-label'>Nombre*</Typography>
              <TextField fullWidth variant='outlined' value={state.name} onChange={e => setState({name: e.target.value})}/>
            </Box>
            <Box className='flex-column'>
              <Typography className='PaymentForm-label'>Email*</Typography>
              <TextField type='email' fullWidth variant='outlined' value={state.email} onChange={e => setState({email: e.target.value})}/>
            </Box>
          </Box>
          <PaymentElement/>
          <Box className='PaymentForm-totalCost'>
            <Box className='flex-row space-between'>
              <Typography>Subtotal</Typography>
              <Typography className='money-value'>$ {toMoneyFormat(subTotal,2)}</Typography>
            </Box>
            <Box className='flex-row space-between'>
              <Typography>IVA (16%)</Typography>
              <Typography className='money-value'>$ {toMoneyFormat(taxes,2)}</Typography>
            </Box>
            <Divider/>
            <Box className='flex-row space-between'>
              <Typography>Monto total</Typography>
              <Typography className='money-value'>$ {toMoneyFormat(total,2)}</Typography>
            </Box>
          </Box>
          <PayButton>Pagar $ {toMoneyFormat(total, 2)}</PayButton>
      </form>
  )

  function handleReturlURL(): string {
    props.completePayment(state.name, state.email);
    return "https://www.valgoritmo.mx/Payment/Complete";
  }
}