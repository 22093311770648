import { Box, MenuItem, Typography } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { ILayout, QuestionKeys, brandDefinitions, formLayout, questions } from '../../constants/questions';
import FormSection from './FormSection';
import './Form.css';
import VTButton from '../button/VTButton';
import FormProgressWrapper from './form-wrapper/FormProgressWrapper';
import VTTextField from '../inputs/text-field/TextField';
import VTSingleCheckBox from '../inputs/check-box/VTSingleCheckBox';
import VTSelect from '../inputs/select/Select';
import { useLoading } from '../loading/LoadingContext';
import VTSlider from '../inputs/slider/VTSlider';
import VTMoneyTextField from '../inputs/money-text-field/MoneyTextField';
import { useBackEnd } from '../../lib/backend';
import { currencies } from '../../constants/company-data';

interface IFormProps {
    layouts: Record<string, ILayout>,
    children?: ReactNode,
    onValuate: Function,
    errors: Record<string,string>,
    step: number,
    setStep: (nv: number) => void,
}

interface IFormState {
    completedSteps: number,
    values: Record<QuestionKeys, any>,
}

export default function Form(props: IFormProps) {

    const {start, stop} = useLoading();
    const backend = useBackEnd();

    const [state, changeState] = useState<IFormState>({
        completedSteps: 0,
        values: {
            email: '',
            name: '',
            terms: false,
            privacy: undefined,
            continent: 'América',
            country: 55,
            sector: 10,
            industry: 10101010,
            age: 5,
            currency: 'Peso Mexicano',
            averageOperativeMargin: 10,
            brandDefinition: brandDefinitions.at(1),
            sales1: '',
            sales2: '',
            sales3: '',
            sales4: '',
            sales5: '',
            salesGrowth: 5,
            debtBackupPercentage: 40,
            interestRate: 14,
            isr: 30,
            renownLadder: 4,
            bruteAverageMargin: 40,
            averageClientsPayDay: '0',
            averageInventoryDays: '0',
            averageProvidersPayDays: '0',
            debtInFinance: '',
            annualCompanyDepreciation: '',
            capex: '',
            numberOfShares: '1'
        },
    });
    const {layouts, errors, step, setStep} = props;
    const {completedSteps} = state;

    const [serverData, setServerData] = useState<Record<string, any> | null>(null);

    const setState = (nv: Partial<IFormState>) => changeState(prev => ({...prev, ...nv}));
    const setValues = (nv: Partial<Record<QuestionKeys, any>>) => setState({values: {...state.values, ...nv}});

    const sections = Object.entries(layouts);

    const completed = completedSteps === sections.length;
    const isFirst = step === 0;

    const handleValuate = () => {

        props.onValuate(state.values);

        // const sectionAnswers: IAnswers = {};
        // if(step === 3) {
        //     sections.at(step)?.[1].questions.forEach(question => {

        //         const key = question as QuestionKeys;

        //         if(key === 'sales2' && parseInt(answers.age!.toString()) < 2) return;
        //         if(key === 'sales3' && parseInt(answers.age!.toString()) < 3) return;
        //         if(key === 'sales4' && parseInt(answers.age!.toString()) < 4) return;
        //         if(key === 'sales5' && parseInt(answers.age!.toString()) < 5) return;
        //         sectionAnswers[key] = answers[key];
        //     });
        // } else {
        //     sections.at(step)?.[1].questions.forEach(question => sectionAnswers[question as QuestionKeys] = answers[question as QuestionKeys]);

        // }

        // const errors = checkValues(sectionAnswers);
        // if(Object.entries(errors).length === 0) {
        //     props.onValuate(answers);
        // }

        // setErrors(errors);
    }

    useEffect(() => {
        if(serverData === null) {
            handleGetServerData();
        }
    });

    if(serverData === null) {
        return <Box/>
    }

    return (
        <Box className='Form-root'>
            <Box className='Form-forms'>
                <Box className='Form-body'>
                    <FormSection layout={formLayout.general} state={getSectionState(0, step)} >
                        <Box className='FormSection-col'>
                            <Box className='FormSection-row'>
                                <VTTextField value={state.values.email} label={questions.email.label}  errorText={errors.email as string} onChange={(newValue) => setValues({email: newValue})}/>
                            </Box>
                            <Box className='FormSection-row'>
                                <VTTextField value={state.values.name} label={questions.name.label}  errorText={errors.name as string} onChange={(newValue) => setValues({name: newValue})}/>
                            </Box>
                            <Box className='FormSection-row'>
                                <VTSingleCheckBox value={state.values.terms} errorText={errors.terms as string} onValueChange={() => setValues({terms: !state.values.terms})} label={questions.terms.label} >
                                    {questions.terms.children}
                                </VTSingleCheckBox>
                            </Box>
                        </Box>
                        <Box className='FormSection-col'>
                            <Box className='FormSection-row'>
                                <VTSlider value={state.values.age} onChange={(newValue: any) => setValues({age: newValue})} label={questions.age.label} min={questions.age.min} max={questions.age.max}/>
                            </Box>
                            <Box className='FormSection-row'>
                                <VTSelect value={state.values.currency} onChange={(newValue) => setValues({currency: newValue})} label={questions.currency.label} >
                                    {
                                        currencies.map(currency => (
                                            <MenuItem key={currency} value={currency}>{currency}</MenuItem>
                                        ))
                                    }
                                </VTSelect> 
                            </Box>
                            <Box className='FormSection-row'>
                                <VTTextField type='number' label={questions.numberOfShares.label} errorText={errors.numberOfShares as string}  value={state.values.numberOfShares} onChange={(newValue) => setValues({numberOfShares: newValue})}/>
                            </Box>
                        </Box>
                    </FormSection>
                    <FormSection layout={formLayout.location} state={getSectionState(1, step)} >

                        <Box className='FormSection-col'>
                            <Box className='FormSection-row'>
                                <Box className='FormSection-col'>
                                    <Typography className='FormSection-row FormSection-subtitle'>Ambiente Geográfico (Oficinas centrales):</Typography>
                                    <Box className='FormSection-row'>
                                        <VTSelect value={state.values.continent} onChange={nv => handleUpdateLocation('countries', nv)} label={questions.continent.label} >
                                            {
                                                serverData.continents.map((continent: {id: number, continent: string}) => (
                                                    <MenuItem key={continent.id} value={continent.continent}>{continent.continent}</MenuItem>
                                                ))
                                            }
                                        </VTSelect> 
                                        <VTSelect value={state.values.country} onChange={(newValue) => setValues({country: newValue})} label={questions.country.label} >
                                            {
                                                serverData.countries.map((countries: Record<string, any>) => (
                                                    <MenuItem key={countries.id} value={countries.id}>{countries.country}</MenuItem>
                                                ))
                                            }
                                        </VTSelect> 
                                    </Box>
                                </Box>
                                <Box className='FormSection-col'>
                                    <Typography className='FormSection-row FormSection-subtitle'>Ambiente Competitivo (Negocio más importante):</Typography>
                                    <Box className='FormSection-row'>
                                        <VTSelect value={state.values.sector} onChange={nv => handleUpdateLocation('industries', nv)} label={questions.sector.label} >
                                            {
                                                serverData.sectors.map((sectors: Record<string, any>) => (
                                                    <MenuItem key={sectors.sector} value={sectors.sector}>{sectors['sector-spanish']}</MenuItem>
                                                ))
                                            }
                                        </VTSelect> 
                                        <VTSelect value={state.values.industry} onChange={(newValue) => setValues({industry: newValue})} label={questions.industry.label} >
                                            {
                                                serverData.industries.map((industry: Record<string, any>) => (
                                                    <MenuItem key={industry['sub-industry']} value={industry['sub-industry']}>{industry['sub-spanish']}</MenuItem>
                                                ))
                                            }
                                        </VTSelect> 
                                    </Box>
                                </Box>
                            </Box>
                            <Box className='FormSection-row' sx={{width: '50% !important'}}>
                                <Box className='FormSection-col' >
                                    <Typography className='FormSection-row FormSection-subtitle'>Ambiente en el mercado:</Typography>
                                    <Box className='FormSection-col'>
                                        <Box className='FormSection-row'>
                                            <VTSelect value={state.values.brandDefinition} onChange={(newValue) => setValues({brandDefinition: newValue})} label={questions.brandDefinition.label} >
                                                {
                                                    brandDefinitions.map(d => (
                                                        <MenuItem key={d} value={d}>{d}</MenuItem>
                                                    ))
                                                }
                                            </VTSelect> 
                                        </Box>
                                        <Box className='FormSection-row'>
                                            <VTSlider value={state.values.renownLadder} onChange={(newValue: any) => setValues({renownLadder: newValue})} label={questions.renownLadder.label} min={questions.renownLadder.min} max={questions.renownLadder.max}/>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </FormSection>
                    <FormSection layout={formLayout.company} state={getSectionState(2, step)} >
                        <Box className='FormSection-col'>
                            <Box className='FormSection-row'>
                                <Box className='FormSection-col'>
                                    <Typography className='FormSection-row FormSection-subtitle'>Ingresos totales</Typography>
                                    <Box className='FormSection-row'>
                                        { state.values.age >= 5 && <VTMoneyTextField label={questions.sales5.label} errorText={errors.sales5 as string}  value={state.values.sales5} onChange={(newValue) => setValues({sales5: newValue})}/>}
                                        { state.values.age >= 4 && <VTMoneyTextField label={questions.sales4.label} errorText={errors.sales4 as string}  value={state.values.sales4} onChange={(newValue) => setValues({sales4: newValue})}/>}
                                        { state.values.age >= 3 && <VTMoneyTextField label={questions.sales3.label} errorText={errors.sales3 as string}  value={state.values.sales3} onChange={(newValue) => setValues({sales3: newValue})}/>}
                                        { state.values.age >= 2 && <VTMoneyTextField label={questions.sales2.label} errorText={errors.sales2 as string}  value={state.values.sales2} onChange={(newValue) => setValues({sales2: newValue})}/>}
                                        <VTMoneyTextField label={questions.sales1.label} errorText={errors.sales1 as string}  value={state.values.sales1} onChange={(newValue) => setValues({sales1: newValue})}/>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className='FormSection-row'>
                                <VTSlider value={state.values.bruteAverageMargin} onChange={(newValue: any) => setValues({bruteAverageMargin: newValue})} label={questions.bruteAverageMargin.label} min={questions.bruteAverageMargin.min} max={questions.bruteAverageMargin.max}/>
                            </Box>
                            <Box className='FormSection-row'>
                                <VTSlider value={state.values.averageOperativeMargin} onChange={(newValue: any) => setValues({averageOperativeMargin: newValue})} label={questions.averageOperativeMargin.label} min={questions.averageOperativeMargin.min} max={questions.averageOperativeMargin.max}/>
                            </Box>
                            <Box className='FormSection-row'>
                                <VTSlider value={state.values.isr} onChange={(newValue: any) => setValues({isr: newValue})} label={questions.isr.label} min={questions.isr.min} max={questions.isr.max}/>
                            </Box>
                        </Box>
                    </FormSection>
                    <FormSection layout={formLayout.companyData} state={getSectionState(3, step)} >
                        <Box sx={{width: '50%', display: 'flex', flexDirection: 'column', '& .VTInput-root ': {marginTop: 4}}}>
                            <VTMoneyTextField label={questions.annualCompanyDepreciation.label} errorText={errors.annualCompanyDepreciation as string}  value={state.values.annualCompanyDepreciation} onChange={(newValue) => setValues({annualCompanyDepreciation: newValue})}/>
                            <VTMoneyTextField label={questions.capex.label} errorText={errors.capex as string}  value={state.values.capex} onChange={(newValue) => setValues({capex: newValue})}/>
                        </Box>
                    </FormSection>
                    <FormSection layout={formLayout.inventory} state={getSectionState(4, step)} >
                        <Box sx={{marginTop: 0, display: 'flex', flexDirection: 'column' ,'& .VTInput-root ': {marginTop: 4}}}>
                            <VTTextField placeholder='0' type='number' label={questions.averageClientsPayDay.label} errorText={errors.averageClientsPayDay as string}  value={state.values.averageClientsPayDay} onChange={(newValue) => setValues({averageClientsPayDay: newValue})}/>
                            <VTTextField placeholder='0' type='number' label={questions.averageInventoryDays.label} errorText={errors.averageInventoryDays as string}  value={state.values.averageInventoryDays} onChange={(newValue) => setValues({averageInventoryDays: newValue})}/>
                            <VTTextField placeholder='0' type='number' label={questions.averageProvidersPayDays.label} errorText={errors.averageProvidersPayDays as string}  value={state.values.averageProvidersPayDays} onChange={(newValue) => setValues({averageProvidersPayDays: newValue})}/>
                        </Box>
                    </FormSection>
                    <FormSection layout={formLayout.finances} state={getSectionState(5, step)} >
                        <Box sx={{marginTop: 0, display: 'flex', flexDirection: 'column', '& .VTInput-root ': {marginTop: 2}}}>
                            <VTSlider value={state.values.debtBackupPercentage} onChange={(newValue: any) => setValues({debtBackupPercentage: newValue})} label={questions.debtBackupPercentage.label} min={questions.debtBackupPercentage.min} max={questions.debtBackupPercentage.max}/>
                            <VTMoneyTextField label={questions.debtInFinance.label} errorText={errors.debtInFinance as string}  value={state.values.debtInFinance} onChange={(newValue) => setValues({debtInFinance: newValue})}/>
                            <VTSlider value={state.values.interestRate} onChange={(newValue: any) => setValues({interestRate: newValue})} label={questions.interestRate.label} min={questions.interestRate.min} max={questions.interestRate.max}/>
                        </Box>
                    </FormSection>
                    
                </Box>
                <Box className='Form-footer flex-row space-between items-center'>
                    <VTButton disabled={isFirst} color='gray' onClick={() => handleNavButton(0)}>Regresar</VTButton>
                    <VTButton disabled={step === sections.length - 1} onClick={() => handleNavButton(1)}>Continuar</VTButton>
                </Box>
            </Box>
            <FormProgressWrapper 
                serverData={serverData}
                values={state.values} 
                onValuate={handleValuate} 
                completed={completed} 
                sections={sections} 
                step={step} 
                completedSteps={state.completedSteps}
            />
        </Box>
    )

    function handleGetServerData() {
        start();
        backend.getData('', '/form/data').then(
            async response => {
                if(response.status !== 200){

                    stop();
                } else {
                    const data = await response.json();

                    setServerData(data);
                    stop();
                }
            }
        )
    }

    function handleNavButton (direction: 0 | 1):void {
        if(direction) {

            if(step === completedSteps) {
                if(completedSteps + 1 < sections.length - 1) {
                    setState({completedSteps: completedSteps + 1})
                } else setState({completedSteps: sections.length});
            }
            if(step === sections.length - 1) return;
            else setStep(step + 1);

        } else {
            if(step === 0) return;
            else setStep(step - 1);
        }
    }

    async function handleUpdateLocation(id: 'countries' | 'industries', newValue: string | number) {

        start();

        const response = await backend.getData('', `/form/${id}?from=${newValue}`);

        let nv = null;
        if(response.status !== 200) {
        } else {
            const results = await response.json();
        
            if(id === 'countries') {
                setServerData({...serverData, countries: results.data});
            } else {
                setServerData({...serverData, industries: results.data});
            }
            nv = results.nv;
        }

        console.log(newValue);

        if(id === 'countries') setValues({continent: newValue, country: nv});
        else setValues({sector: newValue, industry: nv});
        
        setTimeout(() => {
            stop();
        }, 500);

    }
    
    function getSectionState(index: number, step: number): 'after' | 'current' | 'before' {
        if(step < index) return 'after';
        else if(step === index) return 'current';
        else return 'before';
    }
}