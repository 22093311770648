import * as React from 'react';
import VTTextField, { IVTTextFieldProps, IVTTextFieldState } from '../text-field/TextField';
import { fromMoneyToJoinedFormat, isValidMoneyChar, toMoneyFormat } from '../../../utils';

export interface IVTMoneyTextFieldProps extends IVTTextFieldProps {
    decimals?: number,
}

export interface IVTMoneyTextFieldState extends IVTTextFieldState{
    placeholder: string;
}

export default class VTMoneyTextField extends React.Component<IVTMoneyTextFieldProps, IVTMoneyTextFieldState> {
  constructor(props: IVTMoneyTextFieldProps) {
    super(props);

    const {decimals = 0} = props;
    
    let placeholder = this.props.placeholder ? this.props.placeholder : '0';

    placeholder = toMoneyFormat(placeholder, decimals);

    this.state = {placeholder: placeholder}

  }

  handleChange = (newValue: string) => {
    if(newValue.length === 0){
        if(this.props.onChange) this.props.onChange(newValue);
    };

    let number = '';

    let pointFlag = false;

    newValue.split('').forEach(char => {
        
        if(char === '.' && pointFlag) {}
        else {
            if(char === '.' && !pointFlag) pointFlag = true;
            if(isValidMoneyChar(char)) number += char;
        }
         
    });
    newValue = fromMoneyToJoinedFormat(number);
    if(this.props.onChange) this.props.onChange(newValue)
  }

  public render() {

    const {value, ...other} = this.props;
    const {placeholder} = this.state;

    const valueDecimals = value.toString().split('.')[1];

    const suffix = valueDecimals === undefined ? '.00' : valueDecimals.length >= 2 ? '' : '00'.substring(0, 2 - valueDecimals.length);

    return (
      <div>
        <VTTextField type='money-text-field' prefix='$' suffix={suffix} {...other} placeholder={placeholder} onChange={this.handleChange} value={toMoneyFormat(value)}/>
      </div>
    );
  }
}
