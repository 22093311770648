import { Box } from '@mui/material';
import * as React from 'react';

import './Landing.css';
import LandingBanner from './LandingBanner';
import Footer from '../../components/footer/Footer';
import assets from '../../assets';
import PlanSection from './plan-section/PlanSection';
import FAQ from './faq/FAQ';

export interface ILandingProps {
}


interface ILandingState {
}

export default class Landing extends React.Component<ILandingProps, ILandingState> {

  bannerHeader = 'Democratizando la valuación de Marcas y Empresas';
  bannerSubHeader = 'Rápido. Simple. Confiable.';

  emphasisWords = ['Marcas', 'Empresas'];

  public render() {
    return (
      <Box id='landing-page' className='main-view'>
        {/* <img className='VTBackgroundImage' src={assets.images.homeBackground} alt=''/> */}
        <LandingBanner emphasis={this.emphasisWords} header={this.bannerHeader} subHeader={this.bannerSubHeader}/>
        <PlanSection />
        <FAQ/>
        <Footer/>
        <span className='background-circle'/>
        <span className='background-circle second-circle'/>
        <span className='background-triangle first-triangle'><img src={assets.svg.triangle} alt='' /></span>
        <span className='background-triangle second-triangle'><img src={assets.svg.triangle} alt='' /></span>
        <span className='background-triangle third-triangle'><img src={assets.svg.triangle} alt='' /></span>
      </Box>
    );
  }
}
