import images from './images';
import documents from './documents';
import svg from './svg';

const assets = {
    images,
    svg,
    documents,
};

export default assets;