import { Box, Typography } from '@mui/material';
import { useSearchParams} from 'react-router-dom';
import './CompanyResults.css';
import './../brand-results/BrandResults.css';
import Footer from '../../components/footer/Footer';
import { useEffect, useRef, useState } from 'react';
import { useBackEnd } from '../../lib/backend';
import FormResults from '../../components/form-results/FormResults';

export default function CompanyResults() {

    const [selected, setSelected] = useState(0);
    const [blocked, setBlocked] = useState(true);

    const [height, setHeight] = useState(0);

    const [results, setResults] = useState<Record<string, any> | null>(null);
    
    const backend = useBackEnd();
    const [searchParams] = useSearchParams();

    const [labels, setLabels] = useState<Record<string, string>>({});

    const companyRef = useRef<HTMLDivElement | undefined>(null);
    const brandRef = useRef<HTMLDivElement | undefined>(null);

    useEffect(() => {
        if(companyRef.current !== null) {
            setHeight(companyRef.current!.clientHeight! + 125);
        }
        const id = searchParams.get('id');
        backend.getData('token', '/form?id='+id).then(async response => {
            if(response.status === 200) {
                const json = await response.json();
                const {result, blocked, labels} = json; 
                setResults(result);
                setBlocked(blocked);
                setLabels(labels);
                if(companyRef.current !== null) {
                    setHeight(companyRef.current!.clientHeight! + 125);
                } else {
                    setHeight(2700);
                }
            } else {
                
                console.log('invalid');
            }
        })
    }, []);



    if(results === null) {
        return(
            <Box/>
        )
    } else {

        const {answers, companyValuation, brandValuation, periods, companyPeriods} = results;

        return (
            <Box className={'CompanyResults-root '  + (selected === 1 ? 'brand-banner ' : '') + (blocked ? 'VT-blocked' : '')}>
                <Box className={'CompanyResults-bg '}/>
                <Box className='CompanyResults-wrapper' height={height}>
                    <FormResults
                        id='company'
                        ref={companyRef}
                        key='company'
                        bannerData={{
                            name: answers.name,
                            text: 'Valor de la Empresa:',
                            id: answers.id!,
                            onChange: () => {
                                setHeight(brandRef.current?.clientHeight!);
                                setSelected(1);
                            },
                            value: companyValuation.companyValue,
                        }}
                        blocked={blocked}
                        selected={selected === 0}
                    >
                        <Box className='CompanyResultsCards-row BrandValue-cards flex-row full-width space-between'>
                            <Box className='CompanyResults-card'>
                                <Typography className='CompanyResultsCard-title'>Valor de mercado del capital</Typography>
                                <Typography className='CompanyResultsCard-value'>{companyValuation.capitalMarketValue}</Typography>
                            </Box>
                            <Box className='CompanyResults-card'>
                                <Typography className='CompanyResultsCard-title'>Numero de acciones</Typography>
                                <Typography className='CompanyResultsCard-value'>{companyValuation.numberOfShares}</Typography>
                            </Box>
                            <Box className='CompanyResults-card'>
                                <Typography className='CompanyResultsCard-title'>Valor estimado por acción</Typography>
                                <Typography className='CompanyResultsCard-value'>{companyValuation.estimatedShareValue}</Typography>
                            </Box>
                        </Box>
                        <Box className='CompanyResultsCards-row flex-row full-width space-between'>
                            <Box className='CompanyResults-card Periods-card'>
                                <Typography className='CompanyResultsCard-title'>Proyección a 5 años</Typography>
                                <PeriodsTable periods={companyPeriods} labels={labels} inYears/>
                            </Box>
                        </Box>
                        <Box className='CompanyResultsCards-row BrandValuations-row flex-row full-width space-between'>
                            <Box className='CompanyResults-card'>
                                <Typography className='CompanyResultsCard-title'>Respuestas</Typography>
                                <Typography className='CompanyResultsCard-value'>Valores contestados en el formulario</Typography>
                                <ResultsTable values={answers} labels={labels}/>
                            </Box>
                            <Box className='CompanyResults-card'>
                                <Typography className='CompanyResultsCard-title'>Valuación</Typography>
                                <Typography className='CompanyResultsCard-value'>Empresa</Typography>
                                <ResultsTable values={companyValuation} labels={labels}/>
                            </Box>
                        </Box>
                    </FormResults>
                    <FormResults
                        id='brand'
                        ref={brandRef}
                        key='brand'
                        blocked={blocked}
                        selected={selected === 1}
                        bannerData={{
                            name: answers.name,
                            text: 'Valor de la Marca:',
                            id: answers.id!,
                            onChange: () => {
                                setHeight(companyRef.current?.clientHeight!);
                                setSelected(0);
                            },
                            value: brandValuation.brandValue,
                        }}
                    >
                        <Box className='CompanyResultsCards-row BrandValue-cards flex-row full-width space-between'>
                            <Box className='CompanyResults-card'>
                                <Typography className='CompanyResultsCard-title'>Valor de marca inferior</Typography>
                                <Typography className='CompanyResultsCard-value'>{brandValuation.brandValueInf}</Typography>
                            </Box>
                            <Box className='CompanyResults-card'>
                                <Typography className='CompanyResultsCard-title'>Valor de marca superior</Typography>
                                <Typography className='CompanyResultsCard-value'>{brandValuation.brandValueSup}</Typography>
                            </Box>
                        </Box>
                        <Box className='CompanyResultsCards-row flex-row full-width space-between'>
                            <Box className='CompanyResults-card full-width'>
                                <Typography className='CompanyResultsCard-title'>Periodos</Typography>
                                <PeriodsTable periods={periods} labels={labels}/>
                            </Box>
                        </Box>
                        
                        <Box className='CompanyResultsCards-row BrandValuations-row flex-row full-width space-between'>
                            <Box className='CompanyResults-card'>
                                <Typography className='CompanyResultsCard-title'>Respuestas</Typography>
                                <Typography className='CompanyResultsCard-value'>Valores contestados en el formulario</Typography>
                                <ResultsTable values={answers} labels={labels}/>
                            </Box>
                            <Box className='CompanyResults-card'>
                                <Typography className='CompanyResultsCard-title'>Valuación</Typography>
                                <Typography className='CompanyResultsCard-value'>Según el método de regalías NIF C8</Typography>
                                <ResultsTable values={brandValuation} labels={labels}/>
                            </Box>
                        </Box>
                    </FormResults>
                </Box>
                <Footer/>
            </Box>
        )
    }
}


function ResultsTable(props: {values: Record<string, any>, labels: Record<string, string>}) {

    const {labels, values} = props;

    return (
        <Box className='PeriodsTable-root'>
            <Box className='PeriodsTable-row with-bottom-border'>
                <Box className='PeriodsTable-cell PeriodsTable-header'>Etiqueta</Box>
                <Box className='PeriodsTable-cell'>Valor</Box>
            </Box>
            {
                Object.entries(values).map(([k, value]) => {

                    const label = labels[k];
                    return (
                        <Box key={k}  className='PeriodsTable-row'>
                            <Box className='PeriodsTable-cell PeriodsTable-header'>{label}</Box>
                            <Box className='PeriodsTable-cell'>{value}</Box>
                        </Box>
                    )
                })
            }
        </Box>
    )
}
interface IPeriodsTableProps{
    inYears?: boolean
    periods: Array<{[key: string]: number}>
    labels: Record<string, string>,
}

function PeriodsTable(props: IPeriodsTableProps) {

    const {periods, labels, inYears} = props;

    const periodsHeaders = Object.keys(periods.at(0)!).map(key => [[key],labels[key]]);

    return (
        <Box className='PeriodsTable-root'>
            { inYears &&
                <Box className='PeriodsTable-row'>
                    <Box className='PeriodsTable-cell PeriodsTable-header'>Año</Box>
                    {
                        periods.map((period, index) => (
                            <Box key={index} className='PeriodsTable-cell' sx={{textAlign: 'center !important'}}>{new Date().getFullYear() + index + 1}</Box>
                        ))
                    }
                </Box>
            }
            <Box className='PeriodsTable-row with-bottom-border'>
                <Box className='PeriodsTable-cell PeriodsTable-header'>Periodo</Box>
                {
                    periods.map((period, index) => (
                        <Box key={index} className='PeriodsTable-cell' sx={{textAlign: 'center !important'}}>{(index + 1)}</Box>
                    ))
                }
            </Box>
            {
                periodsHeaders.map(([k, header]) => {

                    const key = k as string;

                    const hasBorder = k === 'salesCost' || k === 'operativeExpenses' || k === 'CAPEX';

                    return (
                        <Box key={header+''} className={'PeriodsTable-row' + (hasBorder ? ' with-bottom-border' : '')}>
                            <Box className='PeriodsTable-cell PeriodsTable-header'>{header}</Box>
                            {
                                periods.map((period, index) => (
                                    <Box key={index} className='PeriodsTable-cell no-wrap'>
                                        {period[key]}
                                    </Box>
                                ))
                            }
                        </Box>
                    )
                })
            }
        </Box>
    )
}