'use client'
import { Box } from '@mui/material';
import { IPlansProps } from '../../constants/plans';

interface IPlanButtonProps {
    selected: boolean,
    plan: IPlansProps,
    onClick: () => void,
}


export default function PlanButton(props: IPlanButtonProps) {

    const {plan, selected} = props;

    const {name} = plan;
    
    return (
        <Box className={'PlanButton-root' + (selected ? ' VT-active' : '')} onClick={props.onClick}>
            {name}
        </Box>
    )
}