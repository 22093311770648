'use client'
import { Box, Button, Typography } from '@mui/material';
import './OrderComplete.css';
import assets from '../../assets';
import Footer from '../../components/footer/Footer';

export default function OrderComplete() {
    
    return (
        <Box className='OrderComplete-root'>
            <Box className='OrderComplete-body'>
                <Box className='OrderComplete-box'>
                    <Typography className='OrderComplete-img-text'>
                    <a href="https://www.freepik.com/free-vector/happy-family-jumping-with-huge-piggybank-cartoon-illustration_12699114.htm#query=finances%20happy%20vector&position=22&from_view=search&track=ais&uuid=83953511-33e6-4575-b29a-59486f35dc40">Image by pch.vector</a> on Freepik
                    </Typography>
                    <img src={assets.images.celebration} alt=''/>
                    <Typography className='OrderComplete-title'>¡Gracias por tu aportación!</Typography>
                    <Typography className='OrderComplete-description'>Ahora puedes disfrutar de todos los beneficios de Valgoritmo y ver los detalles de todas las valuaciones asosciadas a tu cuenta de correo.</Typography>
                    <Box className='flex-row' width={'100%'} sx={{justifyContent: 'space-around'}}>
                        <Button href='/'>Inicio</Button>
                    </Box>
                </Box>
            </Box>
            <Footer/>
        </Box>
    )
}