import { Box, Typography } from '@mui/material';
import { ILayout, } from '../../constants/questions';

import { ReactNode } from 'react';

interface IFormSectionProps {
    layout: ILayout,
    state: 'after' | 'current' | 'before',
    children: ReactNode,
}


export default function FormSection(props: IFormSectionProps) {

    const {layout} = props;

    const {title, description, display} = layout;

    return (
        <Box className={'FormSection-root VT-'+(props.state) + (display === 'col' ? ' VT-col' : '')}>
            <Typography className='FormSection-title'>{title}</Typography>
            {formatDescription(description)}
            <Box className='FormSection-body'>
              {props.children}
            </Box>
        </Box>
    );

  function formatDescription(value: string): ReactNode {

    const description = value.split('<br/>');

    return <Box className='FormSection-description'>
        {
          description.map((section, index) => (
            <Typography key={index}>{section}</Typography>
          ))
        }
    </Box>
  }

}