import { Box } from '@mui/material';
import * as React from 'react';
import './CallToAction.css';
import VTProps from '../general/VTProps';
import { createRipples } from '../../utils';

interface ICallToActionProps extends VTProps {
    children?: string;
    onClick?: () => void,
}

export default class CallToAction extends React.Component<ICallToActionProps> {

  handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      createRipples({event: e});
      if(this.props.onClick) this.props.onClick();
  }

  public render() {

    const {color, children} = this.props;

    return (
      <Box className={'VTCallToAction-root ' + color} onClick={this.handleClick}>
        {children}
      </Box>
    );
  }
}
