import { ReactNode, createContext, useContext } from 'react';

const API_URL = process.env.REACT_APP_API_URL!

const postRequestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
}

// const postFilesRequestOptions = {
//     method: 'POST',
//     // headers: {'Content-Type': 'form-data'},
// }
// const patchRequestOptions = {
//     method: 'PATCH',
//     headers: {'Content-Type': 'application/json'},
// }
// const putRequestOptions = {
//     method: 'PUT',
//     headers: {'Content-Type': 'application/json'},
// }
// const deleteRequestOptions = {
//     method: 'DELETE',
//     headers: {'Content-Type': 'application/json'},
// }

interface IBackEndContextProps {
    sendData: (token: string, data: Record<string, any>) => Promise<Response>,
    getData: (token: string, url: string) => Promise<Response>,
    postData: (token: string, url: string, data: Record<string, any>) => Promise<Response>,
}

const BackEndContext = createContext<IBackEndContextProps>({
    sendData: function (token: string, data: Record<string, any>): Promise<Response> {
        throw new Error('Function not implemented.');
    },
    getData: function (token: string, url: string): Promise<Response> {
        throw new Error('Function not implemented.');
    },
    postData: function (token: string, url: string, data: Record<string, any>): Promise<Response> {
        throw new Error('Function not implemented.');
    }
});

const BackEndProvider = ({children}: {children: ReactNode}) => {

    return (
        <BackEndContext.Provider value={{
            sendData,
            getData,
            async postData(token, url, data) {
                const response = await fetch(API_URL+url, {
                    ...postRequestOptions,
                    body: JSON.stringify({token, data}),
                })
        
                return response;
            },
        }}>
            {children}
        </BackEndContext.Provider>
    );

    async function sendData(token: string, data: Record<string, any>): Promise<Response> {
        const response = await fetch(API_URL+'/form', {
            ...postRequestOptions,
            body: JSON.stringify({token, data}),
        })

        return response;
    }

    async function getData(token: string, url: string): Promise<Response> {
        const response = await fetch(API_URL+url);

        return response;
    }

}

export const useBackEnd = () => {
    return useContext(BackEndContext)
}

export default BackEndProvider;