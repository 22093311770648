'use client'
import { Box, Typography } from '@mui/material';
import './AboutView.css';
import Footer from '../../components/footer/Footer';
import assets from '../../assets';
import { useNavigate } from 'react-router-dom';

export default function AboutView() {

    const navigate = useNavigate();

    const ids = [
        ['how', '¿Cómo funciona?'],
        ['profile', 'Ingresa tu perfil'],
        ['valuation', 'Recibe tu valuación'],
        ['history','Nuestra historia'],
    ];
    
    return (
        <Box className='AboutView-root'>
            <Box className='AboutView-wrapper'>

                <Box className='AboutView-side'>
                    {
                        ids.map(([id, text]) => (
                            <a href={'#'+id} key={id}>{text}</a>
                        ))
                    }
                </Box>
                <Box id='how' className='AboutView-body'>

                    <img className='AboutView-banner' src={assets.images.thinker} alt=''/>

                    <Typography className='AboutView-title'>¿Cómo funciona?</Typography>
                    <Typography className='AboutView-text vt-bold'>Un gran valor puede tener varios precios, te daremos uno justificado.</Typography>
                    <Typography className='AboutView-text'>
                        Valgoritmo es un servicio que le da certeza a una comunidad global de negocios.
                        <br/>
                        <br/>
                        Usa Valgoritmo como una brújula para encontrar un “norte” del valor de tu marca y de tu negocio para que tomes buenas decisiones estratégicas.
                    </Typography>
                    <ValuateBtn id='profile'/>


                    <Typography className='AboutView-title'>Ingresa tu perfil</Typography>
                    <Typography className='AboutView-text'>
                        ¿El primer paso? ¡Completa el formulario con el perfil de tu negocio! Se trata de información clave que nos permite comprender características como: en dónde funciona tu marca y tu negocio, qué tan productivo y rentable es lo que haces, y toda esta información es clave para determinar un valor mediante nuestros algoritmos
                    </Typography>
                    <ValuateBtn id='valuation'/>

                    <Typography className='AboutView-title'>Recibe tu valuación</Typography>
                    <Typography className='AboutView-text'>
                        Lo siguiente, ¡recibe tu valuación! Cuando recibas una estimación razonable, basada en cifras numéricas  tanto del valor de tu marca como de tu negocio entenderás un poco mejor cómo funciona Valgoritmo.
                    </Typography>
                    <ValuateBtn/>


                    <img className='AboutView-banner' src={assets.images.handshake} alt='' style={{objectPosition: '0 40%'}}/>

                    <Typography id='history' className='AboutView-text'>
                        Creemos que el mundo de los negocios es mejor a medida en que comprendemos el verdadero valor de las cosas.
                    </Typography>


                    <Typography className='AboutView-title'>Nuestra Historia</Typography>
                    <Typography className='AboutView-text'>
                        Valgoritmo surgió a finales de 2023, después de que dos amigos apasionados de las finanzas y los números. 
                        <br/><br/>
                        Una reunión en un café en el que nos sentamos a platicar acerca de ciencia y la posibilidad de usar un algoritmo para valuar prácticamente cualquier cosa material, fue lo que dio nacimiento a esta idea.
                    </Typography>
                    <ValuateBtn/>

                </Box>
            </Box>
            <Footer/>
        </Box>
    )
    function ValuateBtn({id}: {id?: string}) {
    
        return (
            <Box id={id} className='AboutView-valuate-btn' onClick={() => navigate('/Form')}>¡Valuemos ahora!</Box>
        )
    }
}
