import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { createRipples, toMoneyFormat } from '../../../utils';
import { IPlansProps } from '../../../constants/plans';

const tableCategories = {
    form: {
        name: 'Formulario',
        titles: [
            {name: 'Acceso al formulario', value: 'formAccess'},
            {name: 'Estimación de valor', value: 'valuation'}, 
        ],
    },
    details: {
        name: 'Valuaciones', 
        titles: [
            {name: 'Ilimitadas', value: 'ilimited'},
            {name: 'Documento', value: 'document'},
            {name: 'Detalles', value: 'details'},
        ],
    },
}

export interface IPlanTableProps {
    plans: Array<IPlansProps>,
}

interface IPlanTableState {
}

export default class PlanTable extends React.Component<IPlanTableProps, IPlanTableState> {
    constructor(props: IPlanTableProps) {
        super(props);

        this.state = {
        }
    }

    mapCategories = () => {

        const arrayCategories = Object.entries(tableCategories);

        const mapedValues = arrayCategories.map(([key, values]) => {
            return [key, Object.values(values.titles).map((v) => v.value)];
        });

        const object = Object.fromEntries(mapedValues);

        return object;
    }

    handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, type: string) => {
        createRipples({event: e});
    }

    public render() {

        const {plans} = this.props;

        const mappedCategories = this.mapCategories();

        return (
        <Box className='PlanTable-root'>
            <Box className='PlanTable-categories'>
                <Box className='PlanTable-cell PlanTable-title-container'>
                    <Typography className='PlanTable-title'></Typography>
                    <Typography className='PlanTable-sub-title'></Typography>
                </Box>
                <Box className='PlanTable-categories-categories'>
                    <PlanTableCategory name='Precio' titles={[]}/>
                    {
                        Object.values(tableCategories).map((categories, index) => (
                            <PlanTableCategory key={index} {...categories} />
                        ))
                    }
                </Box>
                <Box className='PlanTable-cell last'></Box>
            </Box>
            <Box className='PlanTable-plans'>
                {
                    plans.map((plan, index) => (
                        <PlanTablePlan key={index} {...plan} categories={mappedCategories} onClick={this.handleClick}/>
                    ))
                }
            </Box>
        </Box>
        );
    }
}

interface ICategoryProps{
    name: string,
    titles: Array<{name: string, value: string}>, 
}

function PlanTableCategory(props: ICategoryProps){

    const {name, titles} = props;

    return(
        <Box className='PlanTable-category'>
            <Typography className='PlanTable-cell PlanTable-category-name'>{name}</Typography>
            {
                titles.map((title, index) => (
                    <PlanTableTitle key={index}>{title.name}</PlanTableTitle>
                ))
            }
            <Divider className='PlanTable-category-separator'/>
        </Box>
    )
}

interface ITitleProps{
    children: string,
}

function PlanTableTitle(props: ITitleProps){

    const {children} = props;

    return(
        <Typography className='PlanTable-cell PlanTable-category-title'>{children}</Typography>
    )
}

interface IPlansTablePlansProps extends IPlansProps{
    categories: Record<string, Array<string>>,
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>, type: string) => void,
}

function PlanTablePlan(props: IPlansTablePlansProps) {
    
    const {name, values, categories, type, price, onClick} = props;

    return(
        <Box className={'PlanTable-plan-container ' + type} onClick={e => onClick(e, type)}>
            <Box className='PlanTable-plan-title'>{name}</Box>
            <PlanTablePlanCategory notitle values={{price: typeof price === 'number' ? '$ '+toMoneyFormat(price) : price}} titles={['price']}/>
            {
                Object.entries(categories).map(([key, titles]) => (
                    <PlanTablePlanCategory key={key} values={values[key]} titles={titles}/>
                ))
            }
            <Box className='PlanTable-cell last'>
                Contratar
            </Box>
        </Box>
    )
}

interface IPlanCategoryProps{
    notitle?: boolean,
    values: Record<string, boolean | string>,
    titles: Array<string>,
}

function PlanTablePlanCategory(props: IPlanCategoryProps) {
    
    const {values, titles, notitle} = props;

    return(
        <Box className='PlanTable-plan-category'>
            {!notitle && <Box className='PlanTable-cell'></Box>}
            {
                titles.map(title => (
                    <Box key={title} className='PlanTable-cell'>
                        {typeof values[title] === 'boolean' ? values[title] ? <Check/> : '-' : values[title]}
                    </Box>
                ))
            }
        </Box>
    )
}