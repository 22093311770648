import { ReactNode, createContext, useContext, useState } from 'react';
import Loading from './Loading';

interface ILoadingContextProps {
    start: () => void,
    stop: () => void,
}

const LoadingContext = createContext<ILoadingContextProps>({
    start: function (): void {
        throw new Error('Function not implemented.');
    },
    stop: function (): void {
        throw new Error('Function not implemented.');
    }
});

const LoadingProvider = ({children}: {children: ReactNode}) => {
    
    const [loading, setLoading] = useState(false);

    return (
        <LoadingContext.Provider value={{
            start: () => setLoading(true),
            stop: () => setLoading(false),
        }}>
            {loading && <Loading/>}
            {children}
        </LoadingContext.Provider>
    )


}

export const useLoading = () => {
    return useContext(LoadingContext)
}

export default LoadingProvider;