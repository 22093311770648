import './App.css';
import './components/inputs/inputs.css';
import './styles/colors.css';
import './styles/animations.css';
import './components/general/general.css';

import { RouterProvider } from 'react-router-dom'
import router from './router'
import BackEndProvider from './lib/backend';

const App = () => {
  return (
    <div className='App'>
      <BackEndProvider>
        <RouterProvider router={router} />
      </BackEndProvider>
    </div>
  )
}

export default App