type Props = {
  route?: string,
  children?: string,
  selected?: boolean,
  onClick?: (route: string) => void,
}

const TopBarButton = (props: Props) => {

  const {children, selected, route} = props;

  const handleOnClick = () => {
    if(!selected && route && props.onClick){
      props.onClick(route);
    }
  }

  return (
    <div className={'top-bar-btn' + (selected ? ' selected' : '')} onClick={handleOnClick}>
      {children}
    </div>
  )
}

export default TopBarButton