import { currencies } from "./company-data";
import { ReactNode } from "react";
import { Typography } from "@mui/material";
import assets from "../assets";

export type TQuestionValues = string | boolean | number;

export const filler = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis auctor lectus. Morbi vulputate suscipit eros, non tempor nibh semper at.';

export interface IQuestion {
    label: string,
    answer: string,
    type: 'text' | 'number' | 'money' | 'switch' | 'select' | 'single-check-box' | 'multi-select' | 'slider' | 'group-select',
    defaultValue: TQuestionValues,
    items?: TQuestionValues[],
    min?: number,
    max?: number,
    children?: ReactNode,
    answerType: 'text' | 'money' | 'boolean' | 'percentage'
}

export const brandDefinitions = ['... es MUY reconocida en el mercado', '… no es tan conocida en el mercado, pero tiene una ventaja competitiva relevante'];

export type QuestionKeys = 
    'email' | 
    'name' | 
    'terms' |
    'privacy' |
    'continent' | 
    'country' | 
    'sector' | 
    'industry' | 
    'age' | 
    'currency' | 
    'averageOperativeMargin' | 
    'brandDefinition' | 
    'sales1' |
    'sales2' |
    'sales3' |
    'sales4' |
    'sales5' |
    'salesGrowth' | 
    'debtBackupPercentage' |
    'interestRate' |
    'isr' |
    'renownLadder' | 
    'bruteAverageMargin' |
    'averageClientsPayDay' |
    'averageInventoryDays' |
    'averageProvidersPayDays' |
    'debtInFinance' |
    'annualCompanyDepreciation' |
    'capex' | 
    'numberOfShares'
;

export type IAnswers = Partial<Record<QuestionKeys, TQuestionValues>>;

export const questions: Record<QuestionKeys, IQuestion> = {
    email: {
        label: 'Email',
        type: 'text',
        defaultValue: '',
        answer: "Email",
        answerType: "text"
    },
    name: {
        label: 'Nombre de la marca',
        type: 'text',
        defaultValue: '',
        answer: "Nombre",
        answerType: "text"
    },
    terms: {
        label: 'Aviso de privacidad y Terminos y Condiciones',
        type: 'single-check-box',
        defaultValue: false,
        answer: "TyC",
        answerType: "boolean",
        children: <Typography key={0}>He leido el <span className="underlined link"><a href={assets.documents.terms} target="_blank" rel="noreferrer">Aviso de privacidad</a></span> y acepto los <span className='underlined link'><a rel="noreferrer" href={assets.documents.terms} target="_blank">Términos y Condiciones</a></span></Typography>
    },
    privacy: {
        label: 'Aviso de privacidad',
        type: 'single-check-box',
        defaultValue: false,
        answer: "AdP",
        answerType: "boolean",
        children: <Typography key={0}>He leido el <span className='underlined link'>Aviso de Privacidad</span></Typography>,
    },
    continent: {
        label: "Continente",
        answer: "Continente",
        type: "select",
        defaultValue: 0,
        items: [],
        answerType: "text"
    },
    country: {
        label: "País",
        answer: "País",
        type: "select",
        defaultValue: 10,
        items: [],
        answerType: "text"
    },
    sector: {
        label: "Sector",
        answer: "Sector",
        type: "select",
        defaultValue: 3,
        items: [],
        answerType: "text"
    },
    industry: {
        label: "Industria",
        answer: "Industria",
        type: "select",
        defaultValue: 16,
        items: [],
        answerType: "text"
    },
    age: {
        label: "Antigüedad de la Empresa (años)",
        answer: "Años",
        type: "slider",
        defaultValue: 5,
        min: 1,
        max: 20,
        answerType: "text"
    },
    currency: {
        label: "Moneda de estados financieros",
        answer: "Moneda",
        type: "select",
        defaultValue: 0,
        answerType: "text",
        items: currencies,
    },
    averageOperativeMargin: {
        label: "Margen operativo promedio últimos años: se calcula un porcentaje de dividir tu Utilidad Operativa / Ventas Totales",
        answer: "Margen operativo",
        type: "slider",
        defaultValue: 10,
        min: 0,
        max: 50,
        answerType: "percentage"
    },
    brandDefinition: {
        label: "Podemos decir que tu marca…",
        answer: "La marca / empresa",
        type: "select",
        defaultValue: 1,
        items: brandDefinitions,
        answerType: "text"
    },
    sales1: {
        label: "Últimos 12 meses",
        answer: "Últimos 12 meses",
        type: "money",
        defaultValue: "",
        answerType: "money"
    },
    sales2: {
        label: "De hace 2 años",
        answer: "De hace 2 años",
        type: "money",
        defaultValue: "",
        answerType: "money"
    },
    sales3: {
        label: "De hace 3 años",
        answer: "De hace 3 años",
        type: "money",
        defaultValue: "",
        answerType: "money"
    },
    sales4: {
        label: "De hace 4 años",
        answer: "De hace 4 años",
        type: "money",
        defaultValue: "",
        answerType: "money"
    },
    sales5: {
        label: "De hace 5 años",
        answer: "De hace 5 años",
        type: "money",
        defaultValue: "",
        answerType: "money"
    },
    salesGrowth: {
        label: "¿Qué porcentaje crecerán las ventas año con año en promedio para los próximos 5 años?",
        answer: "Crecimiento de ventas",
        type: "slider",
        defaultValue: 5,
        answerType: "percentage",
        min: 1,
        max: 20,
    },
    debtBackupPercentage: {
        label: "¿Qué porcentaje de tus activos está respaldado por deudas que tienen costos de intereses?",
        answer: "% de activos respaldados por deudas",
        type: "slider",
        defaultValue: 40,
        answerType: "percentage",
        min: 0,
        max: 100,
    },
    interestRate: {
        label: "¿Cuál es la tasa de interés que paga tu empresa por el crédito a mayor plazo?",
        answer: "Tasa de interes",
        type: "slider",
        defaultValue: 14,
        answerType: "percentage",
        min: 0,
        max: 100,
    },
    isr: {
        label: "¿Qué tasa de impuesto sobre la renta pagas?",
        answer: "ISR",
        type: "slider",
        defaultValue: 30,
        answerType: "percentage",
        min: 1,
        max: 100,
    },
    renownLadder: {
        label: "Si pudieras ubicar tu marca en una escalera del 0 al 10 en cuanto a renombre en la industria en la que participas, siendo 10 la marca con mayor renombre y 0 la que menos ¿en qué peldaño de la escalara te ubicarías?",
        answer: "Posicionamiento (0-10)",
        type: "slider",
        defaultValue: 4,
        answerType: "text",
        min: 0,
        max: 10,
    },
    //Company
    bruteAverageMargin: {
        label: "Margen bruto promedio últimos años (Utilidad bruta / ventas totales)",
        answer: "Margen bruto",
        type: "slider",
        defaultValue: 40,
        answerType: "percentage",
        min: 1,
        max: 100,
    },
    averageClientsPayDay: {
        label: "En general ¿cuántos días te toma cobrar las cuentas de tus clientes desde que se emite la factura?",
        answer: "Dias para cobrar",
        type: "number",
        defaultValue: "",
        answerType: "text"
    },
    averageInventoryDays: {
        label: "En promedio ¿cuántos días está “sentado” el inventario dentro de tu negocio?",
        answer: "Días de inventario sentado",
        type: "number",
        defaultValue: "",
        answerType: "text"
    },
    averageProvidersPayDays: {
        label: "En promedio ¿cuántos días te estás tomando para pagar las cuentas pendientes a tus proveedores?",
        answer: "Días para pagar",
        type: "number",
        defaultValue: "",
        answerType: "text"
    },
    debtInFinance: {
        label: "¿Cúal es el monto de la deuda con costo financiero?",
        answer: "Deuda con costo financiero",
        type: "money",
        defaultValue: "",
        answerType: "money"
    },
    annualCompanyDepreciation: {
        label: "¿Cuánto es la depreciación anual promedio?",
        answer: "Depreciación anual promedio",
        type: "money",
        defaultValue: "",
        answerType: "money"
    },
    capex: {
        label: "CAPEX: ¿Cuánto se invirtió en promedio anual para gastos de capital? (ver Actividades de Inversión)",
        answer: "CAPEX",
        type: "money",
        defaultValue: "",
        answerType: "money"
    },
    numberOfShares: {
        label: "Número de acciones que tiene la empresa",
        answer: "# de acciones",
        type: "number",
        defaultValue: "",
        answerType: "text"
    },
}

export interface ILayout {
    title: string,
    display: 'col' | 'row',
    questions: Array<QuestionKeys>,
    description: string,
}

export const formLayout: Record<string, ILayout> = {
    general: {
        title: 'Identifica lo que valuaremos',
        description: `
        Completa este formulario con la mayor veracidad posible. Quizás requieras el apoyo de un especialista si no tienes familiaridad con los estados financieros.<br/>

        El producto es más acertado mientras la información que proporciones sea más exacta.<br/>
        
        IMPORTANTE: Este producto supone que la información que proporcionas se refiere a un solo negocio y todas sus marcas en conjunto. Si tienes varias marcas dentro de un mismo negocio, el resultado se agrupa en una sola valuación. En otras palabras, si quieres conocer el valor de cada marca que tiene tu negocio, debes ingresar los valores como si fueran negocios individuales. Si necesitas una valuación más detallada o con ciertos parámetros especiales, contáctanos.`,
        questions: ['email', 'name', 'age', 'currency', 'numberOfShares', 'terms'],
        display: "col"
    },

    location: {
        title: '¿En qué ambiente funciona tu negocio?',
        description: 'Esta información nos permite comprender el entorno económico de tu negocio, así como la naturaleza del sector y los competidores de tu negocio ¡Todo esto afecta en el costo que tiene el capital para un negocio como el tuyo!',
        questions: ['continent', 'country', 'sector', "industry", "brandDefinition", "renownLadder"],
        display: "col"
    },
    company:  {
        title: 'De tu Estado “de Resultados” o “Ganancias y Pérdidas”',
        description: 'Imagina que vemos los ingresos totales de tu negocio  en una línea de tiempo',
        questions: ['sales1', 'sales2', "sales3", "sales4", "sales5", "bruteAverageMargin", "averageOperativeMargin", 'isr'],
        display: "col"
    },
    companyData: {
        title: 'De tu Estado “de Flujo de Efectivo”',
        description: '',
        questions: ['annualCompanyDepreciation', 'capex'],
        display: "col"
    },
    inventory: {
        title: '¿Cómo fluye tu capital de trabajo?',
        description: 'Todos manejamos los negocios de acuerdo a ciertas políticas de crédito (cuando vendemos y cuando compramos a proveedores)<br/>¿Cómo va este negocio? Por ejemplo, si no manejas inventario o si no das crédito, agrega un “0”',
        questions: ['averageClientsPayDay', 'averageInventoryDays', 'averageProvidersPayDays'],
        display: "col"
    },
    finances: {
        title: 'Situacion financiera del negocio',
        description: '',
        questions: ['debtBackupPercentage','debtInFinance','interestRate',],
        display: "col"
    },

}

const validateEmail = (email:string | undefined): boolean => {

    const a = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    return Boolean(a);
  };

export const checkValues = (values: IAnswers): IAnswers => {

    const errors: IAnswers = {};
    
    if(values.name === '') errors.name = 'No puede estar vacio';
    if(values.email === '') errors.email = 'No puede estar vacio';
    else if(values.email !== undefined && !validateEmail(values.email as string)) errors.email = 'Email invalido';
    if(values.terms === false) errors.terms = '';
    if(values.privacy === false) errors.privacy = '';
    if(values.sales1 === '') errors.sales1 = 'No puede estar vacio';
    if(values.sales2 === '') errors.sales2 = 'No puede estar vacio';
    if(values.sales3 === '') errors.sales3 = 'No puede estar vacio';
    if(values.sales4 === '') errors.sales4 = 'No puede estar vacio';
    if(values.sales5 === '') errors.sales5 = 'No puede estar vacio';

    if(values.bruteAverageMargin === '') errors.bruteAverageMargin = 'No puede estar vacio';

    if(values.averageClientsPayDay === '') errors.averageClientsPayDay = 'Solo se aceptan números enteros';
    else if(values.averageClientsPayDay && Number.isNaN(parseInt(values.averageClientsPayDay!.toString()))) errors.averageClientsPayDay = 'Solo se aceptan números enteros';

    if(values.averageInventoryDays === '') errors.averageInventoryDays = 'Solo se aceptan números enteros'; 
    else if( values.averageInventoryDays && Number.isNaN(parseInt(values.averageInventoryDays!.toString()))) errors.averageInventoryDays = 'Solo se aceptan números enteros';

    if(values.averageProvidersPayDays === '') errors.averageProvidersPayDays = 'Solo se aceptan números enteros'; 
    else if( values.averageProvidersPayDays && Number.isNaN(parseInt(values.averageProvidersPayDays!.toString()))) errors.averageProvidersPayDays = 'Solo se aceptan números enteros';

    if(values.debtInFinance === '') errors.debtInFinance = 'No puede estar vacio'; 

    if(values.annualCompanyDepreciation === '') errors.annualCompanyDepreciation = 'No puede estar vacio'; 

    if(values.capex === '') errors.capex = 'No puede estar vacio'; 

    if(values.numberOfShares === '') errors.numberOfShares = 'No puede estar vacio';

    return errors;
}