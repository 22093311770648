import { Box, Typography } from '@mui/material';
import { OverridableStringUnion } from "@mui/types";
import * as React from 'react';

import { VTInputProps } from '../inputs';

export interface IVTTextFieldProps extends VTInputProps {
  prefix?: string,
  suffix?: string,
  variant?: OverridableStringUnion<'default' | 'contained'>
  onChange?: (newValue: string) => void;
}

export interface IVTTextFieldState {
}

export default class VTTextField extends React.Component<IVTTextFieldProps, IVTTextFieldState> {
  constructor(props: IVTTextFieldProps) {
    super(props);

    this.state = {
    }
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(this.props.onChange){
      const newValue = e.target.value;
      this.props.onChange(newValue);
    }
  }

  public render() {

    const {label, placeholder, prefix, value, suffix, type, errorText, variant = 'default', fullwidth, sx = {}, required} = this.props;
    const {minwidth} = this.props;

    const error = errorText !== undefined;

    return (
      <Box className={'VTInput-root' + (this.props.required ? ' VT-required' : '') + (error ? ' VT-error' : '') + ' VT-' + variant} sx={{width: fullwidth ? '100%' : null, minWidth: minwidth ? '0 !important' : null, ...sx}}>
        <Typography className='VTInput-label'>{label}</Typography>
        <Box className= {'VTTextField-container ' + type} sx={{width: fullwidth ? 'calc(100% - 26px) !important' : null, minWidth: minwidth ? '0 !important' : null}}>
          {prefix && <Typography className={'VTTextField-prefix'}>{prefix}</Typography>}
          <input className={suffix ? 'with-suffix' : ''} placeholder={placeholder} value={value} onChange={this.handleChange} type={type === 'number' ? type : 'text'} required={required}/>
          {suffix && <Typography className={'VTTextField-suffix'}>{suffix}</Typography>}
          <Box className='VTTextField-bottomLine'/>
        </Box>
        <Box className='VTInput-errortext'>{errorText}</Box>
      </Box>
    );
  }
}
