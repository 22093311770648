export interface IPlansProps {
    name: string,
    price: string,
    priceSecondary?: string,
    description: string,
    type: string,
    values: Record<string, Record<string, boolean>>,
  }

const plans: Array<IPlansProps> = [
    {
      name: 'Gratis',
      price: 'MXN $0',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus rhoncus',
      type: 'free',
      values: {
        form: {
          formAccess: true,
          valuation: true,
        },
        details: {
          ilimited: false,
          document: false,
          details: false,
        },
      }
    },
    {
      name: 'Día',
      price: 'MXN $1,499',
      priceSecondary: '($547,135 / año)',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus rhoncus',
      type: 'monthly',
      values: {
        form: {
          formAccess: true,
          valuation: true,
        },
        details: {
          ilimited: true,
          document: true,
          details: true,
        },
      }
    },
    {
      name: 'Anual',
      price: 'MXN $9,999',
      priceSecondary: '($27 / día)', 
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus rhoncus',
      type: 'yearly',
      values: {
        form: {
          formAccess: true,
          valuation: true,
        },
        details: {
          ilimited: true,
          document: true,
          details: true,
        },
      }
    },
  
]

export default plans;